import React, { useEffect, useState } from 'react';
import css from './InputFile.module.scss';
import cx from 'classnames';
import Icon from '../Icon/Icon';

interface InputFileProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  changed?: any;
  accept?: string;
  customInput?: boolean;
  iconInput?: string;
}

const InputFile = React.forwardRef(
  (props: InputFileProps, inputRef: React.Ref<HTMLInputElement>) => {
    const [files, setFiles] = useState<any[]>([]);

    const onChangeHandler = async (selectedFiles: any) => {
      const newFilesContainer = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const base64: any = await convertBase64(file);
        const formattedBase64 = base64.split(',')[1];
        const fileName = file.name;
        const fileExt = file.type;
        const completeFile = {
          name: fileName,
          ext: fileExt,
          file: formattedBase64,
          size: file.size,
        };
        newFilesContainer.push(completeFile);
      }
      setFiles(newFilesContainer);
    };

    const convertBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      });
    };

    useEffect(() => {
      if (files.length > 0) {
        props.changed(files);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    return (
      <label
        htmlFor={props.name}
        className={cx(props.className, {
          [css.customInput]: props.customInput,
          [css.iconInput]: props.iconInput,
        })}
      >
        {props.label}
        {props.customInput && (
          <>
            <Icon icon='upload-cloud' gray />
            <p>
              Trascina qui i file da allegare
              <br />
              oppure <span>caricali</span>
            </p>
          </>
        )}
        {props.iconInput && <Icon icon={props.iconInput} gray />}
        <input
          type='file'
          ref={inputRef}
          id={props.name}
          name={props.name}
          multiple
          accept={props.accept}
          onChange={e => onChangeHandler(e.currentTarget.files)}
        />
      </label>
    );
  },
);

export default InputFile;
