import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../store/store';

export const useIsOwner = () => {
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const user = useSelector((state: RootState) => state.user.user);

  const [isOwner, setIsOwner] = useState<boolean>(false);

  useEffect(() => {
    const userExist = campaign.internal_team?.find((u: any) => u.id === user.id);
    if (userExist && userExist.owner) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  }, [user, campaign]);

  return isOwner;
};

export const useIsExternalOwner = () => {
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const user = useSelector((state: RootState) => state.user.user);

  const [isExternalOwner, setIsExternalOwner] = useState<boolean>(false);

  useEffect(() => {
    const userExist = campaign.external_team?.find((u: any) => u.id === user.id);
    if (userExist && userExist.owner) {
      setIsExternalOwner(true);
    } else {
      setIsExternalOwner(false);
    }
  }, [user, campaign]);

  return isExternalOwner;
};

export const useIsInternalMember = () => {
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const user = useSelector((state: RootState) => state.user.user);

  const [isInternalMember, setIsInternalMember] = useState<boolean>(false);

  useEffect(() => {
    const userExist = campaign.internal_team?.find((u: any) => u.id === user.id);
    if (userExist) {
      setIsInternalMember(true);
    }
  }, [user, campaign]);

  return isInternalMember;
};

export const useIsExternalMember = () => {
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const user = useSelector((state: RootState) => state.user.user);

  const [isExternalMember, setIsExternalMember] = useState<boolean>(false);

  useEffect(() => {
    const userExist = campaign.external_team?.find((u: any) => u.id === user.id);
    if (userExist) {
      setIsExternalMember(true);
    }
  }, [user, campaign]);

  return isExternalMember;
};

type ParamTypes = { campaignId: string };

export const useIsTeamMember = () => {
  const [isTeamMember, setIsTeamMember] = useState<boolean | null>(null);

  const { campaignId } = useParams<ParamTypes>();

  const currentUser = useSelector((state: RootState) => state.user.user);
  const campaigns = useSelector((state: RootState) => state.campaigns.campaigns);

  useEffect(() => {
    if (campaignId && currentUser) {
      const realCamp = campaigns.find((campaign: any) => campaign.id === Number(campaignId));

      if (realCamp) {
        const userList = [...realCamp.internal_team, ...realCamp.external_team];
        const isMember = userList.find((user: any) => user.id === currentUser.id);

        if (!isMember) {
          setIsTeamMember(false);
        } else {
          setIsTeamMember(true);
        }
      }
    }
  }, [campaignId, campaigns, currentUser]);

  return isTeamMember;
};
