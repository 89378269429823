import React, { useEffect } from 'react';
import css from './AddNewUser.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { postAddUser } from '../../../store/user/api';
import { clearAddUserCompleted } from '../../../store/user/slice';
import Modal from '../../../components/UI/Modal/Modal';
import Input from '../../../components/UI/Input/Input';
import Select from '../../../components/UI/Select/Select';
import { RootState } from '../../../store/store';
import Parse from 'html-react-parser';

interface AddNewUserProps {
  isOpen: boolean;
  designation: string;
  closed: () => void;
  newUserAdded: (newUser: any) => void;
  inputValue: string;
}

const AddNewUser: React.FC<AddNewUserProps> = ({
  isOpen,
  designation,
  closed,
  newUserAdded,
  inputValue,
}) => {
  const dispatch = useDispatch();

  const addState = useSelector((state: RootState) => state.user.add);

  const { control, errors, handleSubmit, setValue } = useForm<addUserFormTypes>();

  const onSubmit = (data: addUserFormTypes) => {
    dispatch(postAddUser({ ...data, designation: designation, type: data.type.value }));
  };

  // On registration completed, close modal and clear user state
  useEffect(() => {
    if (addState.completed.status) {
      closed();
      newUserAdded(addState.completed.user);
      dispatch(clearAddUserCompleted());
    }
  }, [closed, dispatch, addState.completed, newUserAdded]);

  // Set default value for name and surname
  useEffect(() => {
    const val = inputValue.split(' ');
    setValue('name', val[0]);
    if (val[1]) {
      setValue('surname', val[1]);
    }
  }, [inputValue, setValue]);

  return (
    <>
      <Modal
        title='Nuovo utente'
        isOpen={isOpen}
        submitButton={{ text: 'Crea utente' }}
        onSubmit={handleSubmit(onSubmit)}
        onClose={closed}
      >
        <div className={css.modalWrapper}>
          <div className={css.elementWrapper}>
            <Controller
              name='name'
              as={Input}
              control={control}
              defaultValue=''
              label='Nome <span>*</span>'
              rules={{ required: 'Nome è obbligatorio' }}
              error={errors.name ? 1 : 0}
            />
            {errors.name && <span className={css.error}>{errors.name.message}</span>}
          </div>

          <div className={css.elementWrapper}>
            <Controller
              name='surname'
              as={Input}
              control={control}
              defaultValue=''
              label='Cognome <span>*</span>'
              rules={{ required: 'Cognome è obbligatorio' }}
              error={errors.surname ? 1 : 0}
            />
            {errors.surname && <span className={css.error}>{errors.surname.message}</span>}
          </div>

          <div className={css.elementWrapper}>
            <Controller
              name='email'
              as={Input}
              control={control}
              defaultValue=''
              label='Email <span>*</span>'
              rules={{ required: "L'email è obbligatoria" }}
              error={errors.email ? 1 : 0}
            />
            {errors.email && <span className={css.error}>{errors.email.message}</span>}
          </div>

          <div className={css.elementWrapper}>
            <Controller
              name='type'
              as={Select}
              control={control}
              defaultValue=''
              placeholder='Seleziona il ruolo'
              options={[
                { value: 'dev', label: 'Developer' },
                { value: 'pm', label: 'Project manager' },
                { value: 'other', label: 'Other' },
              ]}
              label='Ruolo <span>*</span>'
              rules={{ required: 'Il ruolo è obbligatorio' }}
              error={errors.type ? 1 : 0}
            />
            {errors.type && <span className={css.error}>{errors.type.message}</span>}
          </div>

          {addState.loading && 'Caricamento'}
          {addState.error && addState.error}
        </div>
      </Modal>
    </>
  );
};

export default AddNewUser;
