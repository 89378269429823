import React from 'react';
import css from './Sidebar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import Icon from '../UI/Icon/Icon';
import { RootState } from '../../store/store';
import { setSidebarOpen } from '../../store/app/slice';
import { togglePanelOpen } from '../../store/notifications/slice';
import Avatar from '../Avatar/Avatar';
import Notifications from './Notifications/Notifications';

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isExpanded = useSelector((state: RootState) => state.app.isSidebarOpen);

  function setIsExpanded(val: boolean) {
    dispatch(setSidebarOpen(val));
  }

  const campaigns = useSelector((state: RootState) => state.campaigns.campaigns);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);
  const isNotificationOpen = useSelector((state: RootState) => state.notifications.isPanelOpen);

  return (
    <div className={css.sidebarWrapper}>
      <div className={css.sidebar}>
        <div className={css.topWrapper}>
          <NavLink
            to='/dashboard'
            isActive={() =>
              ['/dashboard', '/campaign', '/newcampaign', '/editcampaign'].some(str =>
                pathname.includes(str),
              )
            }
          >
            <Icon icon='campaign' light />
          </NavLink>
        </div>
        <div className={css.bottomWrapper}>
          <div
            className={css.notificationIcon}
            onClick={() => {
              if (isExpanded) {
                setIsExpanded(false);
              }
              dispatch(togglePanelOpen());
            }}
          >
            <Icon icon='bell' light />
            {notifications.filter((n: any) => n.read === 0).length > 0 && (
              <span>{notifications.filter((n: any) => n.read === 0).length}</span>
            )}
          </div>

          <Link to='/profile' className={css.profile}>
            <Avatar user={currentUser} size={32} />
          </Link>

          <div className={css.iconExpandSidebar}>
            <span>
              {!isExpanded && (
                <Icon
                  icon='chevron-right'
                  light
                  onClick={() => {
                    setIsExpanded(true);
                    if (isNotificationOpen) {
                      dispatch(togglePanelOpen());
                    }
                  }}
                ></Icon>
              )}
            </span>
          </div>
        </div>
      </div>

      {isNotificationOpen && (
        <Notifications closed={() => dispatch(togglePanelOpen())} notifications={notifications} />
      )}

      {isExpanded && (
        <div className={css.expandedSidebar}>
          <h3>Campagne</h3>

          <ul className={css.list}>
            {campaigns.map((campaign: any) => {
              const userList = [...campaign.internal_team, ...campaign.external_team];
              const isMember = userList.find(user => user.id === currentUser.id);
              if (isMember) {
                return (
                  <li key={campaign.id}>
                    <NavLink
                      to={`/campaign/${campaign.id}`}
                      exact
                      onClick={() => setIsExpanded(false)}
                    >
                      {campaign.name}
                    </NavLink>
                  </li>
                );
              }
              return null;
            })}
          </ul>

          {currentUser.designation === 'user' && (
            <div className={css.addCampaign}>
              <Link to='/newcampaign' onClick={() => setIsExpanded(false)}>
                <div className={css.link}>
                  Crea una nuova campagna
                  <span>
                    <Icon icon='add-circle' primary />
                  </span>
                </div>
              </Link>
            </div>
          )}

          <div className={css.collapseSidebar}>
            <Icon icon='chevron-left' light onClick={() => setIsExpanded(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
