import React from 'react';
import css from '../Sidebar.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import Notification from './Notification/Notification';
import { hasReadNotifications } from '../../../store/notifications/api';

interface NotificationsProps {
  closed: () => void;
  notifications: any;
}

const Notifications: React.FC<NotificationsProps> = ({ closed, notifications }) => {
  const dispatch = useDispatch();
  const campaigns = useSelector((state: RootState) => state.campaigns.campaigns);

  return (
    <div className={css.notificationExpanded}>
      <div className={css.notificationContent}>
        <div className={css.notificationHeader}>
          <h3>Notifiche</h3>
        </div>

        <ul className={css.notificationsList}>
          {notifications.map((notification: any) => (
            <Notification
              key={notification.id}
              notification={notification}
              campaign={campaigns.find((campaign: any) => campaign.id === notification.campaign_id)}
              closed={() => {
                dispatch(hasReadNotifications([notification.id]));
                closed();
              }}
            />
          ))}
        </ul>
      </div>
      <div className={css.notificationOverlay} onClick={closed}></div>
    </div>
  );
};

export default Notifications;
