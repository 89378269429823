import React, { useEffect, useState } from 'react';
import css from '../Creativities.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import checkStepCompleted from '../../../../utils/checkSteps';
import Icon from '../../../../components/UI/Icon/Icon';
import moment from 'moment';
import NotificationsBadge from '../../../../components/NotificationsBadge/NotificationsBadge';

interface CampaignCreativityProps {
  creativity: any;
  notifications: any;
}

const CampaignCreativity: React.FC<CampaignCreativityProps> = ({ creativity, notifications }) => {
  const [currentStep, setCurrentStep] = useState<StepTypes>();

  useEffect(() => {
    if (creativity.steps) {
      const current = creativity.steps.find((step: StepTypes) => step.status === 'current');
      if (current) {
        setCurrentStep(current);
      }
    }
  }, [creativity.steps]);

  return (
    <Link
      className={css.box}
      key={creativity.id}
      to={`/campaign/${creativity.campaign_id}/creativity/${creativity.id}/folder/${creativity.folders[0]?.id}`}
    >
      <div className={css.titleBoxCtn}>
        <div className={css.titleBox}>
          <h3>{creativity.name}</h3>
        </div>
        <NotificationsBadge notifications={notifications} />
      </div>

      <div className={css.boxBarCtn}>
        <div className={css.boxBarInner}>
          <h4>Fase</h4>
          <span>{currentStep?.name}</span>
          <ul className={css.steps}>
            {creativity.steps?.map((step: any) => (
              <li
                key={step.id}
                className={cx({
                  [css.completed]: checkStepCompleted(step, currentStep),
                })}
              />
            ))}
          </ul>
        </div>
        <div className={css.boxBar}>
          <div className={css.stepsInfo}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '2px' }}>
              <Icon icon='clock_s' gray />
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '13px',
                  lineHeight: '1.85',
                }}
              >
                {currentStep && (
                  <span>
                    {moment(currentStep?.endDate).diff(moment(new Date()), 'days')} giorni alla
                    nuova fase
                  </span>
                )}
              </span>

              <br />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '2px' }}>
              <Icon icon='send_s' gray />
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '13px',
                  lineHeight: '1.85',
                }}
              >
                online:{' '}
                {creativity.steps.slice(-1)[0] &&
                  moment(creativity.steps.slice(-1)[0].endDate).format('DD/MM/yyyy')}
              </span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CampaignCreativity;
