import React, { useEffect, useState } from 'react';
import css from './Campaign.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { getCampaign, deleteCampaign } from '../../store/campaign/api';
import { clearDeleteCampaign } from '../../store/campaign/slice';
import { RootState } from '../../store/store';
import Creativities from './Creativities/Creativities';
import Icon from '../../components/UI/Icon/Icon';
import DropDownMenu from '../../components/UI/DropDownMenu/DropDownMenu';
import Topbar from '../../components/Topbar/Topbar';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import Avatar from '../../components/Avatar/Avatar';
import { useIsOwner, useIsTeamMember } from '../../hooks/useRoles';
import { hasReadNotifications } from '../../store/notifications/api';

type ParamTypes = { campaignId: string };

const Campaign: React.FC = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isTeamMember = useIsTeamMember();
  const isIntOwner = useIsOwner();

  const { campaignId } = useParams<ParamTypes>();

  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const fetchCampaign = useSelector((state: RootState) => state.campaign.get);
  const loadingCreativities = useSelector((state: RootState) => state.creativities.loading);
  const creativities = useSelector((state: RootState) => state.creativities.creativities);
  const deleteStatus = useSelector((state: RootState) => state.campaign.delete);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (isTeamMember !== null) {
      if (!isTeamMember) {
        history.push('/dashboard');
      } else {
        dispatch(getCampaign(Number(campaignId)));
      }
    }
  }, [campaignId, isTeamMember, dispatch, history]);

  useEffect(() => {
    if (deleteStatus.completed) {
      dispatch(clearDeleteCampaign());
      history.push(`/dashboard`);
    }
  }, [deleteStatus.completed, dispatch, history]);

  const deleteCampaignHandler = () => {
    dispatch(deleteCampaign(campaign.id));
  };

  useEffect(() => {
    const listToRead: any[] = [];
    notifications.forEach((notification: any) => {
      if (
        notification.campaign_id === Number(campaignId) &&
        notification.type === 'new_campaign' &&
        notification.read === 0
      ) {
        listToRead.push(notification.id);
      }
    });

    if (listToRead.length > 0) {
      dispatch(hasReadNotifications(listToRead));
    }
  }, [campaignId, dispatch, notifications]);

  return (
    <>
      <div className={css.row}>
        {fetchCampaign.loading ? (
          'Caricamento'
        ) : (
          <>
            <div className={css.colInfo}>
              <Topbar title={campaign.name} backLink='/dashboard' />
              <div className={css.creativityWrapperTitle}>
                <h3>Creatività</h3>
              </div>

              <div className={css.colInfoContent}>
                {isIntOwner && creativities.length > 0 && (
                  <span className={css.newCreativity}>
                    <Link to='/newcreativity'>
                      <Button icon={{ name: 'add' }} text='' floatTopRight />
                    </Link>
                  </span>
                )}
                {loadingCreativities ? 'Caricamento' : <Creativities />}
              </div>
            </div>

            <div className={css.colOther}>
              <div className={css.campaignOptions}>
                <div className={css.colOtherContent}>
                  <div className={css.rowOther}>
                    <h3>Campagna</h3>

                    {isIntOwner && (
                      <DropDownMenu
                        menuItems={[
                          {
                            label: 'Modifica campagna',
                            icon: 'edit',
                            onClick: () => history.push('/editcampaign'),
                          },
                          {
                            label: 'Elimina campagna',
                            icon: 'trash',
                            onClick: () => setDeleteModal(true),
                          },
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>

              {campaign.description !== '' && (
                <div className={css.desc}>
                  <div className={css.colOtherContent}>
                    <h3>Descrizione</h3>
                    <p>{campaign.description}</p>
                  </div>
                </div>
              )}

              {campaign.files?.length > 0 && (
                <div className={css.filesWrapper}>
                  <div className={css.colOtherContent}>
                    <div className={css.rowOther}>
                      <h3>File allegati</h3>
                      {isIntOwner && (
                        <Icon
                          icon='add-circle'
                          gray
                          onClick={() => history.push('/editcampaign')}
                        />
                      )}
                    </div>

                    <div className={css.filesBox}>
                      {campaign.files.length > 0 ? (
                        <>
                          {campaign.files.map((file, fileIndex) => (
                            <a
                              className={css.fileBox}
                              href={file.abs_path}
                              target='_blank'
                              rel='noreferrer'
                              key={fileIndex}
                            >
                              {file.name}
                            </a>
                          ))}
                        </>
                      ) : (
                        'Nessun file allegato'
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className={css.teamWrapper}>
                <div className={css.internalTeam}>
                  <div className={css.colOtherContent}>
                    <div className={css.rowOther}>
                      <h3>Team</h3>
                      {isIntOwner && (
                        <Icon
                          icon='add-circle'
                          gray
                          onClick={() => history.push('/editcampaign')}
                        />
                      )}
                    </div>
                    <ul className={css.team}>
                      {campaign.internal_team?.map((u: CampaignUserTeamTypes) => (
                        <span key={u.id}>
                          <Avatar user={u} />
                        </span>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className={css.externalTeam}>
                  <div className={css.colOtherContent}>
                    <div className={css.rowOther}>
                      <h3>Team crafted/cliente </h3>
                      {isIntOwner && (
                        <Icon
                          icon='add-circle'
                          gray
                          onClick={() => history.push('/editcampaign')}
                        />
                      )}
                    </div>
                    <ul className={css.team}>
                      {campaign.external_team?.map((u: CampaignUserTeamTypes) => (
                        <span key={u.id}>
                          <Avatar key={u.id} user={u} />
                        </span>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        title='Eliminazione campagna'
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        submitButton={{ text: 'Conferma', type: 'danger', inLoad: deleteStatus.loading }}
        onSubmit={deleteCampaignHandler}
      >
        <div className={css.deleteInfo}>
          <div className={css.deleteIcon}>
            <Icon icon='trash' gray />
          </div>
          <p>
            Sei sicuro di voler eliminare la campagna <strong>"{campaign.name}"</strong>?
          </p>
        </div>
        <div className={css.operationsInfo}>
          <p>Tutte le seguenti operazioni non potranno essere ripristinati</p>

          <div>
            <ul>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutte le cratività verranno eliminate
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i formati e i commenti verranno eliminati
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i link di condivisione non saranno raggiungibili
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Campaign;
