import React, { useEffect, useState, useRef } from 'react';
import css from './SendForApproval.module.scss';
import mentionsCss from './MentionsSendForApproval.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useIsExternalOwner } from '../../../../hooks/useRoles';
import Modal from '../../../../components/UI/Modal/Modal';
import UsersPicker from '../../../../components/UsersPicker/UsersPicker';
import Input from '../../../../components/UI/Input/Input';
import { postSendForApproval } from '../../../../store/approval/api';
import { clearSendForApprovalSuccess } from '../../../../store/approval/slice';
import Icon from '../../../../components/UI/Icon/Icon';
import { MentionsInput, Mention } from 'react-mentions';

interface SendForApprovalProps {
  isOpen: boolean;
  closed: () => void;
  sended?: () => void;
  folder: FolderTypes;
  selectedFormats: any[];
}

const SendForApproval: React.FC<SendForApprovalProps> = ({
  selectedFormats,
  folder,
  isOpen,
  closed,
  sended,
}) => {
  const dispatch = useDispatch();

  const isExtOwner = useIsExternalOwner();

  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const creativity = useSelector((state: RootState) => state.creativity.creativity);
  const add = useSelector((state: RootState) => state.approval.add);

  const [availableUsers, setAvailableUsers] = useState<any[]>([]);
  const [mentionAvailableUsers, setMentionAvailableUsers] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<TeamTypes[]>([]);
  const [message, setMessage] = useState<string>('');

  const messageRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };

  const filteredAvailableUsers = (users: any) => {
    const totalIdApprovals = [] as any[];
    selectedFormats.forEach(format => {
      format.approvals.forEach((f: any) => {
        if (!totalIdApprovals.includes(f.user.id)) {
          totalIdApprovals.push(f.user.id);
        }
      });
    });

    return users.filter((user: any) => {
      return !totalIdApprovals.includes(user.id);
    });
  };

  const generateMentionUsers = (users: any) => {
    const newUsers = [] as any;
    users.forEach((user: any) => {
      newUsers.push({ id: user.id, display: user.name + ' ' + user.surname, email: user.email });
    });

    return newUsers;
  };

  // Populate availableUsers with internal team and external owner
  // Or with external team if current user is external owner
  useEffect(() => {
    if (isExtOwner) {
      const filtered = campaign.external_team.filter((el: any) => el.owner !== true);
      const checkedUsers = filteredAvailableUsers(filtered);
      setAvailableUsers(checkedUsers);
      setMentionAvailableUsers(generateMentionUsers(filtered));
    } else {
      const newAvailableUsers = [...campaign.internal_team];

      const extOwner = campaign.external_team.find((o: any) => o.owner === true);
      if (extOwner) {
        newAvailableUsers.push(extOwner);
      }

      const checkedUsers = filteredAvailableUsers(newAvailableUsers);
      setAvailableUsers(checkedUsers);
      setMentionAvailableUsers(generateMentionUsers(newAvailableUsers));
    }
  }, [campaign, isExtOwner]);

  useEffect(() => {
    if (add.completed) {
      closed();
      if (sended) sended();
      dispatch(clearSendForApprovalSuccess());
    }
  }, [add.completed, closed, sended, dispatch]);

  const submitForApprovalHandler = () => {
    const approvers: number[] = [];
    selectedUsers.forEach((user: TeamTypes) => {
      approvers.push(user.user_id);
    });
    if (folder.id) {
      dispatch(
        postSendForApproval(
          selectedFormats.map(el => el.format),
          selectedFormats.map(el => el.version),
          approvers,
          message,
          folder.id,
        ),
      );
    }
  };

  return (
    <div>
      <Modal
        title='Invia per approvazione'
        isOpen={isOpen}
        onClose={closed}
        submitButton={{ text: availableUsers.length > 0 ? 'Richiedi approvazione' : 'Ok' }}
        onSubmit={() => (availableUsers.length > 0 ? submitForApprovalHandler() : closed())}
      >
        <>
          {availableUsers.length > 0 ? (
            <div className={css.sfaWrapper}>
              {!isExtOwner && selectedUsers.find((u: any) => u.designation === 'client') && (
                <div className={css.sfaAlert}>
                  <span>
                    <Icon icon='alert-triangle' />
                  </span>
                  Attenzione inviando per approvazione i formati saranno visibili all’Owner esterno
                </div>
              )}
              <div className={css.sfaWrapperInfo}>
                <div className={css.sfaInfo}>
                  {campaign.name} | {creativity.name}
                </div>
                <div>
                  {selectedFormats.length} format{selectedFormats.length === 1 ? 'o' : 'i'} di
                  <strong> "{folder.name}"</strong>
                </div>
              </div>

              <UsersPicker
                changed={(state: TeamTypes[]) => setSelectedUsers(state)}
                label='Approvatore <span>*</span>'
                users={availableUsers}
                ownership={false}
              />

              <label>Commento</label>
              <MentionsInput
                style={{ width: '100%' }}
                value={message}
                inputRef={messageRef}
                className='mentions'
                allowSuggestionsAboveCursor
                classNames={mentionsCss}
                onChange={(e: any) => handleChange(e)}
              >
                <Mention
                  trigger='@'
                  data={mentionAvailableUsers as any}
                  className={mentionsCss.mentions__mention}
                  renderSuggestion={(suggestion: any, search, highlightedDisplay) => (
                    <div className={mentionsCss.itemInner}>
                      <div className={mentionsCss.itemAvatar}></div>
                      <div className={mentionsCss.itemInfo}>
                        <h4>{suggestion.display}</h4>
                        <span>{suggestion.email}</span>
                      </div>
                    </div>
                  )}
                />
              </MentionsInput>
              {/* <Input
                value={message}
                name='message'
                label='Commento'
                onChange={(e: any) => setMessage(e.currentTarget.value)}
              /> */}
              {add.error && add.error}
            </div>
          ) : (
            <span style={{ paddingLeft: 25, display: 'block', marginTop: 20, marginBottom: 20 }}>
              Hai già chiesto l'approvazione a tutti gli utenti
            </span>
          )}
        </>
      </Modal>
    </div>
  );
};

export default SendForApproval;
