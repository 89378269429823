import React, { useEffect } from 'react';
import css from './BaseLayout.module.scss';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/user/api';
import { getCampaigns } from '../../store/campaigns/api';
import { getUsers } from '../../store/users/api';
import { getCompany } from '../../store/company/api';
import { getNotifications } from '../../store/notifications/api';
import Sidebar from '../Sidebar/Sidebar';
import { RootState } from '../../store/store';
import Bottombar from '../Bottombar/Bottombar';

const BaseLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isSidebarExpanded = useSelector((state: RootState) => state.app.isSidebarOpen);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getCompany());
    dispatch(getCampaigns());
    dispatch(getUsers());
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <div
      className={cx(css.wrapper, {
        sidebarExpanded: isSidebarExpanded,
      })}
    >
      <Sidebar />
      {children}

      <Bottombar />
    </div>
  );
};

export default BaseLayout;
