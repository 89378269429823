import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import { getUsersSuccess } from './slice';

export const getUsers = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/users?limit=100');
    dispatch(getUsersSuccess(res.data.users));
  } catch (err) {
    console.log(err);
  }
};
