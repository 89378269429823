import axios from 'axios';
import { postLogout, setTokenFromLocalStorage } from './store/user/slice';
import { store } from './store/store';

const { dispatch } = store;

const axiosRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  transformRequest: [
    function (data, headers) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    },
  ],
});

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Custom axios global options
customAxios.interceptors.request.use(
  config => {
    const token = store.getState().user.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Axios response interceptors
customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      return dispatch(postLogout());
    } else if (error.response.status === 412) {
      return resetTokenAndReattemptRequest(error);
    }
    return Promise.reject(error);
  },
);

let isAlreadyFetchingAccessToken = false;
let subscribers = [] as any;

async function resetTokenAndReattemptRequest(error: any) {
  try {
    const { response: errorResponse } = error;

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(() => {
        errorResponse.config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
        resolve(customAxios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axiosRefresh({
        method: 'post',
        url: 'users/refreshToken',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (!response.data) {
        return Promise.reject(error);
      }

      const newToken = response.data.token;
      dispatch(setTokenFromLocalStorage(newToken));
      localStorage.setItem('token', newToken);
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    dispatch(postLogout());
    return Promise.reject(err);
  }
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

function onAccessTokenFetched(access_token: any) {
  subscribers.forEach((callback: any) => callback(access_token));
  subscribers = [];
}

export default customAxios;
