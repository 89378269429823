import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const formatsStore = createSlice({
  name: 'formats',
  initialState: {
    get: {
      loading: false,
    },
    addFolder: {
      loading: false,
      error: null as null | string,
      completed: false,
    },
    editFolder: {
      loading: false,
      error: null as null | string,
      completed: false,
    },
    addFormat: {
      loading: false,
      error: null as null | string,
      completed: false,
    },
    editFormat: {
      loading: false,
    },
    formats: [],
  },
  reducers: {
    // Get formats
    getFormatsLoading(state) {
      state.get.loading = true;
    },
    getFormatsSuccess(state, action: PayloadAction<any>) {
      state.get.loading = false;
      state.formats = action.payload.formats;
    },

    // Add format
    postAddFormatLoading(state) {
      state.addFormat.loading = true;
      state.addFormat.error = null;
    },
    postAddFormatSuccess(state) {
      state.addFormat.loading = false;
      state.addFormat.completed = true;
    },
    postAddFormatError(state, action: PayloadAction<string>) {
      state.addFormat.loading = false;
      state.addFormat.error = action.payload;
    },
    clearAddFormat(state) {
      state.addFormat.completed = false;
    },

    // Edit format
    postUpdateFormatLoading(state) {
      state.editFormat.loading = true;
    },
    postUpdateFormatSuccess(state) {
      state.editFormat.loading = false;
    },

    // Add folder
    postAddFolderLoading(state) {
      state.addFolder.loading = true;
    },
    postAddFolderSuccess(state) {
      state.addFolder.loading = false;
      state.addFolder.completed = true;
    },
    postAddFolderError(state, action: PayloadAction<string>) {
      state.addFolder.loading = false;
      state.addFolder.error = action.payload;
    },
    clearAddFolder(state) {
      state.addFolder.completed = false;
    },

    // Edit folder
    editFolderLoading(state) {
      state.editFolder.loading = true;
    },
    editFolderSuccess(state) {
      state.editFolder.loading = false;
      state.editFolder.completed = true;
    },
    clearFolderStatus(state) {
      state.editFolder.completed = false;
    },
  },
});

export const {
  getFormatsSuccess,
  getFormatsLoading,
  postAddFormatLoading,
  postAddFormatSuccess,
  postAddFormatError,
  clearAddFormat,
  postUpdateFormatLoading,
  postUpdateFormatSuccess,
  postAddFolderLoading,
  postAddFolderSuccess,
  postAddFolderError,
  clearAddFolder,
  editFolderLoading,
  editFolderSuccess,
  clearFolderStatus,
} = formatsStore.actions;
