import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import { getCampaigns } from '../campaigns/api';
import {
  getCampaignSuccess,
  getCampaignLoading,
  getCampaignError,
  postAddCampaignLoading,
  postAddCampaignSuccess,
  postAddCampaignError,
  postEditCampaignLoading,
  postEditCampaignSuccess,
  postEditCampaignError,
  deleteCampaignLoading,
  deleteCampaignSuccess,
} from './slice';
import { getCreativities } from '../creativities/api';

export const getCampaign =
  (paramId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const campaigns = getState().campaigns.campaigns;

      const currentCampaign = campaigns.find((c: CampaignTypes) => c.id === paramId);

      if (currentCampaign) {
        dispatch(getCampaignSuccess(currentCampaign));
      } else {
        dispatch(getCampaignLoading());
        const res = await customAxios.get('/campaigns', { params: { id: paramId } });
        dispatch(getCampaignSuccess(res.data));
      }
      dispatch(getCreativities(paramId));
    } catch (err: any) {
      dispatch(getCampaignError(err.response.data.error));
    }
  };

export const postAddCampaign =
  (data: any): AppThunk =>
  async (dispatch, getState) => {
    const company_id = getState().company.company.id;
    const agency_id = getState().company.company.agency_id;

    try {
      dispatch(postAddCampaignLoading());
      await customAxios.post('/campaigns', {
        agency_id,
        company_id,
        ...data,
      });
      dispatch(postAddCampaignSuccess());
      dispatch(getCampaigns());
    } catch (err) {
      dispatch(postAddCampaignError(err.response.data.error));
    }
  };

export const putEditCampaign =
  (data: any, campaignId: number): AppThunk =>
  async (dispatch, getState) => {
    const company_id = getState().company.company.id;
    const agency_id = getState().company.company.agency_id;

    try {
      dispatch(postEditCampaignLoading());
      console.log(data);
      await customAxios.put(`/campaigns/${campaignId}`, {
        agency_id,
        company_id,
        ...data,
      });
      dispatch(getCampaigns());
      dispatch(postEditCampaignSuccess());
    } catch (err) {
      dispatch(postEditCampaignError(err.response.data.error));
    }
  };

export const deleteCampaign =
  (campaignId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(deleteCampaignLoading());
      await customAxios.delete(`/campaigns/${campaignId}`);
      dispatch(getCampaigns());
      dispatch(deleteCampaignSuccess());
    } catch (err) {}
  };
