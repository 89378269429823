import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { setTokenFromLocalStorage } from '../store/user/slice';

const useCheckLocalStorage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);
  const localStorageToken = localStorage.getItem('token');

  useEffect(() => {
    if (localStorageToken) {
      if (!token) {
        dispatch(setTokenFromLocalStorage(localStorageToken));
      }
    }
  });
};

export default useCheckLocalStorage;
