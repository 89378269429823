import React, { useEffect } from 'react';
import css from './Dashboard.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import Button from '../../components/UI/Button/Button';
import DashboardCampaign from './DashboardCampaign/DashboardCampaign';
import { hasReadNotifications } from '../../store/notifications/api';
import { postLogout } from '../../store/user/slice';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  const notifications = useSelector((state: RootState) => state.notifications.notifications);
  const campaigns = useSelector((state: RootState) => state.campaigns.campaigns);
  const currentUser = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    const listToRead: any[] = [];
    notifications.forEach((notification: any) => {
      if (notification.type === 'campaign_deleted' && notification.read === 0) {
        listToRead.push(notification.id);
      }
    });

    if (listToRead.length > 0) {
      dispatch(hasReadNotifications(listToRead));
    }
  }, [dispatch, notifications]);

  return (
    <>
      <div className={css.campaignTopBar}>
        <div className={css.campaignTopBarTitle}>
          <h3>Campagne</h3>
          <div className='logoClient'>
            <img src={require('../../assets/images/logo-eni.png').default} alt='' />
          </div>
          <div style={{ width: 150 }}></div>
        </div>
        <ul>
          <li className={css.active}>Attive</li>
          <li>Archiviate</li>
        </ul>
      </div>
      <div className={css.tit}>
        {currentUser.designation === 'user' && (
          <span className={css.newCampaign}>
            <Link to='/newcampaign'>
              <Button icon={{ name: 'add' }} text='' floatTopRight />
            </Link>
          </span>
        )}
      </div>
      <div className={css.welcomeTitle}>
        Ciao {currentUser.name}, ci sono{' '}
        {
          campaigns.filter((campaign: any) =>
            [...campaign.internal_team, ...campaign.external_team].find(
              user => user.id === currentUser.id,
            ),
          ).length
        }{' '}
        campagne attive
      </div>

      <div className={css.campaignsWrapper}>
        <ul
          className={cx({
            [css.oneItem]: campaigns.length === 1,
            [css.list]: campaigns.length > 1,
          })}
        >
          {campaigns.map(campaign => {
            const userList = [...campaign.internal_team, ...campaign.external_team];
            const inMember = userList.find(user => user.id === currentUser.id);
            if (inMember) {
              return (
                <li key={campaign.id}>
                  <Link to={`/campaign/${campaign.id}`}>
                    <DashboardCampaign
                      campaign={campaign}
                      notifications={notifications.filter(
                        (notification: any) => notification.campaign_id === campaign.id,
                      )}
                    />
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </>
  );
};

export default Dashboard;
