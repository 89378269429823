import React from 'react';
import css from './Topbar.module.scss';
import { Link, useHistory } from 'react-router-dom';
import Icon from '../UI/Icon/Icon';

interface TopbarProps {
  title?: string;
  subtitle?: string;
  backLink?: string;
}

const Topbar: React.FC<TopbarProps> = ({ title, subtitle, backLink, children }) => {
  const history = useHistory();

  function defaultTitle() {
    return (
      <div className={css.title}>
        <h1>{title}</h1> {subtitle && <span className={css.subtitle}>{subtitle}</span>}
      </div>
    );
  }

  return (
    <div className={css.topbarWrapper}>
      <div className={css.topbarBack}>
        {backLink ? (
          <Link to={backLink}>
            <Icon icon='back' gray />
          </Link>
        ) : (
          <Icon icon='back' gray onClick={() => history.goBack()} />
        )}
      </div>

      {children ? children : defaultTitle()}

      <div className='logoClient'>
        <img src={require('../../assets/images/logo-eni.png').default} alt='' />
      </div>
    </div>
  );
};

export default Topbar;
