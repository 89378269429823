import React, { useState, useRef, useEffect } from 'react';
import css from './DropDownMenu.module.scss';
import cx from 'classnames';
import Icon from '../Icon/Icon';

interface DropDownMenuProps {
  menuItems: DropDownMenuItem[];
  label?: string;
  icon?: string;
  smallMenu?: boolean;
}

interface DropDownMenuItem {
  label: string;
  icon?: string;
  onClick?: () => void;
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({ menuItems, label, icon, smallMenu }) => {
  const node = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOutside = (e: any) => {
    if (node?.current?.contains(e.target)) {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={node}
      className={cx(css.container, {
        [css.smallMenu]: smallMenu,
      })}
    >
      <span
        onClick={e => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {icon && <Icon icon={icon} gray />}
        {label && label}

        {!icon && !label && <Icon icon='more-horizontal' gray />}
      </span>

      {isOpen && (
        <div className={css.dropDownMenu}>
          {menuItems.map((el, index) => (
            <div
              key={index}
              className={css.dropdownItem}
              onClick={e => {
                e.preventDefault();
                setIsOpen(false);
                el.onClick && el.onClick();
              }}
            >
              {el.icon && <Icon icon={el.icon} gray />}
              <span>{el.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;
