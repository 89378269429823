import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { usePopper } from 'react-popper';
import cx from 'classnames';
import css from './DropDown.module.scss';

interface DropDownProps {
  isOpen: boolean;
  parent: Element | null;
  placement?: any;
  smallMenu?: boolean;
  // array di due elementi (x, y)
  offset?: [number | null | undefined, number | null | undefined];
  children?: any;
  style?: any;
  onClose?: () => void;
}

const DropDown = React.forwardRef<HTMLDivElement, DropDownProps>(
  ({ children, isOpen, placement, smallMenu, parent, offset, style, onClose, ...props }, ref) => {
    const [popperElement, setPopperElement] = useState<any | null>(null);
    const [dropDownOpen, setDropDownOpen] = useState<boolean>(isOpen);

    const { styles, attributes } = usePopper(parent, popperElement, {
      placement: placement || 'top-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: offset || [-12, 12],
          },
        },
      ],
    });

    useEffect(() => {
      setDropDownOpen(isOpen);
    }, [isOpen]);

    // Function for click event
    const handleClickOutside = (event: MouseEvent) => {
      if (popperElement && !popperElement.contains(event.target)) {
        //setDropDownOpen(false);
        onClose && onClose();
      }
    };

    // prove per gestire la chiusura al click fuori dal popup
    useEffect(() => {
      if (dropDownOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [popperElement, dropDownOpen]);

    placement = placement || 'top-right';
    const parentEl = document.querySelector('body') as Element;

    return (
      <>
        {dropDownOpen &&
          ReactDom.createPortal(
            <div
              className={cx(css.dropDown, css[placement], css.smallMenu)}
              ref={setPopperElement}
              style={{ ...styles.popper, ...style }}
              {...attributes.popper}
            >
              {children}
              <div className={css.arrow} data-popper-arrow></div>
            </div>,
            parentEl,
          )}
      </>
    );
  },
);

export default DropDown;
