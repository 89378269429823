import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';

import { getCampaignsSuccess } from './slice';

export const getCampaigns = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/campaigns?limit=50');

    dispatch(getCampaignsSuccess(res.data.campaigns));
  } catch (err) {
    console.log(err);
  }
};
