import React from 'react';
import css from './Checkbox.module.scss';
import Parse from 'html-react-parser';

interface InputProps {
  name: string;
  disabled?: boolean;
  type?: string;
  label?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }, ref) => {
  return (
    <>
      <input className={css.customCheckbox} id={props.name} ref={ref} {...props} />
      <label htmlFor={props.name}>{props.label && Parse(props.label)}</label>
    </>
  );
});

export default Checkbox;
