import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { userStore } from './user/slice';
import { usersStore } from './users/slice';
import { authStore } from './auth/slice';
import { campaignsStore } from './campaigns/slice';
import { campaignStore } from './campaign/slice';
import { creativityStore } from './creativity/slice';
import { creativitiesStore } from './creativities/slice';
import { companyStore } from './company/slice';
import { stepsStore } from './steps/slice';
import { formatsStore } from './formats/slice';
import { approvalStore } from './approval/slice';
import { chatStore } from './chat/slice';
import { appStore } from './app/slice';
import { notificationsStore } from './notifications/slice';

const rootReducer = combineReducers({
  auth: authStore.reducer,
  user: userStore.reducer,
  users: usersStore.reducer,
  company: companyStore.reducer,
  campaign: campaignStore.reducer,
  campaigns: campaignsStore.reducer,
  creativity: creativityStore.reducer,
  creativities: creativitiesStore.reducer,
  steps: stepsStore.reducer,
  formats: formatsStore.reducer,
  approval: approvalStore.reducer,
  chat: chatStore.reducer,
  app: appStore.reducer,
  notifications: notificationsStore.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
