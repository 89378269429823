import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const stepsStore = createSlice({
  name: 'steps',
  initialState: {
    stepsTemplate: [],
    steps: [],
  },
  reducers: {
    getStepsSuccess(state, action: PayloadAction<any>) {
      state.steps = action.payload;
    },
    getStepsTemplateSuccess(state, action: PayloadAction<any>) {
      state.stepsTemplate = action.payload;
    },
  },
});

export const { getStepsSuccess, getStepsTemplateSuccess } = stepsStore.actions;
