import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const creativityStore = createSlice({
  name: 'creativity',
  initialState: {
    chatOpen: false,
    infoOpen: false,
    add: {
      loading: false,
      completed: false,
      error: null,
    },
    get: {
      loading: false,
      error: null,
    },
    edit: {
      loading: false,
      error: null,
      completed: true,
    },
    delete: {
      loading: false,
      completed: false,
    },
    creativity: {},
  } as CreativityStoreTypes,
  reducers: {
    toggleChatOpen(state) {
      state.chatOpen = !state.chatOpen;
    },
    setInfoOpen(state, action: PayloadAction<boolean>) {
      state.infoOpen = action.payload;
    },
    // Get creativity
    getCreativityLoading(state) {
      state.get.loading = true;
    },
    getCreativitySuccess(state, action: PayloadAction<CreativityTypes>) {
      state.get.loading = false;
      state.creativity = action.payload;
    },
    getCreativityError(state, action: PayloadAction<string>) {
      state.get.loading = false;
      state.get.error = action.payload;
    },

    // Add creativity
    postAddCreativityLoading(state) {
      state.add.loading = true;
      state.add.error = null;
    },
    postAddCreativitySuccess(state) {
      state.add.completed = true;
      state.add.loading = false;
    },
    postAddCreativityError(state, action: PayloadAction<string>) {
      state.add.loading = false;
      state.add.error = action.payload;
    },
    clearAddCreativityCompleted(state) {
      state.add.completed = false;
    },

    // Edit creativity
    putUpdateCreativityLoading(state) {
      state.edit.loading = true;
    },
    putUpdateCreativitySuccess(state) {
      state.edit.loading = false;
      state.edit.completed = true;
    },
    putUpdateCreativityError(state, action: PayloadAction<string>) {
      state.edit.loading = false;
      state.edit.error = action.payload;
    },
    clearUpdateCreativity(state) {
      state.edit.completed = false;
    },

    // Delete creativity
    deleteCreativityLoading(state) {
      state.delete.loading = true;
    },
    deleteCreativitySuccess(state) {
      state.delete.loading = false;
      state.delete.completed = true;
    },
    clearDeleteCreativity(state) {
      state.delete.completed = false;
    },
  },
});

export const {
  getCreativityLoading,
  getCreativitySuccess,
  getCreativityError,
  postAddCreativitySuccess,
  postAddCreativityLoading,
  postAddCreativityError,
  clearAddCreativityCompleted,
  putUpdateCreativityLoading,
  putUpdateCreativitySuccess,
  putUpdateCreativityError,
  clearUpdateCreativity,
  deleteCreativityLoading,
  deleteCreativitySuccess,
  clearDeleteCreativity,
  toggleChatOpen,
  setInfoOpen,
} = creativityStore.actions;
