import moment from 'moment';

const checkStepCompleted = (step: any, currentStep: any) => {
  if (
    moment(step.beginDate, 'YYYY-MM-DD HH:mm').toDate().getTime() <=
      (currentStep
        ? moment(currentStep.beginDate, 'YYYY-MM-DD HH:mm').toDate().getTime()
        : new Date().getTime()) ||
    step.status === 'current'
  ) {
    return true;
  }
  return false;
};

export default checkStepCompleted;
