import React from 'react';
import css from './Bottombar.module.scss';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Icon from '../UI/Icon/Icon';
import { RootState } from '../../store/store';
import { setPanelOpen } from '../../store/notifications/slice';

const Bottombar: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isPanelOpen = useSelector((state: RootState) => state.notifications.isPanelOpen);
  const unreadNotifications = useSelector(
    (state: RootState) => state.notifications.notifications.filter((n: any) => n.read === 0).length,
  );

  return (
    <div className={css.bottombarWrapper}>
      <div className={css.bottombarLink}>
        <span>
          <NavLink
            to='/dashboard'
            isActive={() =>
              !isPanelOpen && ['/dashboard', '/campaign'].some(str => pathname.includes(str))
            }
            onClick={() => {
              dispatch(setPanelOpen(false));
            }}
          >
            <Icon icon='campaign' gray />
          </NavLink>
        </span>
        <span
          className={cx(css.notificationIcon, { active: isPanelOpen })}
          onClick={() => {
            dispatch(setPanelOpen(true));
          }}
        >
          <Icon icon='bell' gray />
          {unreadNotifications > 0 && <span>{unreadNotifications}</span>}
        </span>
        <span>
          <NavLink
            exact
            isActive={match => !isPanelOpen && match !== null}
            to='/profile'
            className={css.profile}
          >
            <Icon
              icon='profile'
              gray
              onClick={() => {
                dispatch(setPanelOpen(false));
              }}
            />
          </NavLink>
        </span>
      </div>
    </div>
  );
};

export default Bottombar;
