import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const creativitiesStore = createSlice({
  name: 'creativities',
  initialState: {
    loading: false,
    creativities: [] as CreativityTypes[],
  },
  reducers: {
    getCreativitiesLoading(state) {
      state.loading = true;
    },
    getCreativitiesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.creativities = action.payload.creativities;
    },
  },
});

export const { getCreativitiesSuccess, getCreativitiesLoading } = creativitiesStore.actions;
