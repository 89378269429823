import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';

import { getStepsSuccess, getStepsTemplateSuccess } from './slice';

export const getSteps = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/steps');

    dispatch(getStepsSuccess(res.data.steps));
  } catch (err) {
    console.log(err);
  }
};

export const getStepsTemplate = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/steps/templates');

    dispatch(getStepsTemplateSuccess(res.data.step_templates));
  } catch (err) {
    console.log(err);
  }
};
