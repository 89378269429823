import Pusher from 'pusher-js';

//import Echo from 'laravel-echo';
//window.Pusher = require('pusher-js');

/* const pusher = (token: string) =>
  new Echo({
    broadcaster: 'pusher',
    key: 'anyKey',
    wsHost: 'lion-api.fabricandum.com',
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  }); */

//Pusher.logToConsole = true;

const pusher = (token: string) =>
  new Pusher('anyKey', {
    wsHost: 'lion-api.fabricandum.com',
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  });

export default pusher;
