import React, { useState, useEffect } from 'react';
import css from '../../Format.module.scss';
import cx from 'classnames';
import {
  useIsOwner,
  useIsExternalOwner,
  useIsInternalMember,
} from '../../../../../../hooks/useRoles';

interface ApprovalStatusProps {
  approvals: any;
  haveToApprove: any;
  extApprovers: any;
}

const ApprovalStatus: React.FC<ApprovalStatusProps> = ({
  approvals,
  haveToApprove,
  extApprovers,
}) => {
  const isInternalMember = useIsInternalMember();
  const isExtOwner = useIsExternalOwner();

  const [status, setStatus] = useState<{ text: string; type: string }>({ text: '', type: '' });

  useEffect(() => {
    // Check if there is external (client) approvers
    if (extApprovers.length === 0) {
      // Check if there are approvals
      if (approvals.length === 0) {
        setStatus({ text: 'Formato caricato', type: '' });
      }

      // If there is only 1 approver
      if (approvals.length === 1) {
        if (haveToApprove.exist) {
          setStatus({
            text:
              haveToApprove.status === 'approved'
                ? 'Hai approvato'
                : haveToApprove.status === 'rejected'
                ? 'Non hai approvato'
                : 'Da approvare',
            type: haveToApprove.status,
          });
        } else {
          setStatus({
            text:
              approvals[0].status === 'approved'
                ? 'Approvato'
                : approvals[0].status === 'rejected'
                ? 'Non approvato'
                : 'In approvazione',
            type: approvals[0].status,
          });
        }
      }

      // If there are multiple appovers
      if (approvals.length > 1) {
        let tempStatus = { text: 'Approvato', type: 'approved' };
        for (let i = 0; i < approvals.length; i++) {
          if (approvals[i].status === 'new') {
            tempStatus.text = 'In approvazione';
            tempStatus.type = 'new';
          } else if (approvals[i].status === 'rejected') {
            tempStatus.text = 'Non approvato';
            tempStatus.type = 'rejected';
            break;
          }
        }
        setStatus(tempStatus);
      }
    } else {
      if (haveToApprove.exist && isExtOwner) {
        setStatus({
          text:
            haveToApprove.status === 'approved'
              ? 'Hai approvato'
              : haveToApprove.status === 'rejected'
              ? 'Non hai approvato'
              : 'Da approvare',
          type: haveToApprove.status,
        });
      } else {
        setStatus({
          text:
            extApprovers[0].status === 'approved'
              ? 'Approvato'
              : extApprovers[0].status === 'rejected'
              ? 'Non approvato'
              : 'In approvazione',
          type: extApprovers[0].status,
        });
      }
    }
  }, [approvals, isInternalMember, haveToApprove, extApprovers, isExtOwner]);

  return (
    <div className={css.approvalStatus}>
      {status.text && (
        <span
          className={cx({
            [css.new]: status.type === '',
            [css.approved]: status.type === 'approved',
            [css.approvedByExtOwner]: extApprovers[0]?.status === 'approved',
            [css.rejected]: status.type === 'rejected',
            [css.waiting]: status.type === 'new',
          })}
        >
          {status.text}
        </span>
      )}
    </div>
  );
};

export default ApprovalStatus;
