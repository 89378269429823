import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';

import {
  getFolderMessagesSuccess,
  getFolderMessagesLoading,
  postNewFolderMessageSuccess,
  postNewFolderMessageError,
  getFormatMessagesSuccess,
} from './slice';

export const getFolderMessages =
  (folderId: number): AppThunk =>
  async dispatch => {
    try {
      //dispatch(getFolderMessagesLoading());
      const res = await customAxios.get(`creativities/folders/messages/${folderId}`, {
        params: { limit: 100 },
      });
      dispatch(getFolderMessagesSuccess(res.data.messages));
    } catch (err) {
      console.log(err);
    }
  };

export const postNewFolderMessage =
  (message: string, folderId: number, chatType: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const hasError = getState().chat.add.error;
      await customAxios.post(`/creativities/folders/messages/${folderId}`, {
        message,
        destination_chat: chatType,
      });
      if (hasError) {
        dispatch(postNewFolderMessageSuccess());
      }
    } catch (err: any) {
      dispatch(postNewFolderMessageError(err.response.data.error));
    }
  };

export const postNewFormatMessage =
  (message: string, formatId: number, chatType: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      //const hasError = getState().chat.add.error;
      await customAxios.post(`/creativities/formats/messages/${formatId}`, {
        message,
        destination_chat: chatType,
      });
      /* if (hasError) {
        dispatch(postNewFolderMessageSuccess());
      } */
    } catch (err: any) {
      dispatch(postNewFolderMessageError(err.response.data.error));
    }
  };

export const getFormatMessages =
  (formatId: number): AppThunk =>
  async dispatch => {
    try {
      //dispatch(getAllMessagesLoading());
      const res = await customAxios.get(`creativities/formats/messages/${formatId}`, {
        params: { limit: 100 },
      });

      dispatch(getFormatMessagesSuccess({ id: formatId, messages: res.data.messages }));
    } catch (err) {
      console.log(err);
    }
  };

export const readChatMessages =
  (formatId: number, folderId: number): AppThunk =>
  async dispatch => {
    try {
      let endpoint;
      if (formatId) {
        endpoint = `/creativities/folders/messages/read/${folderId}/${formatId}`;
        await customAxios.post(endpoint);
        dispatch(getFormatMessages(formatId));
      } else {
        endpoint = `/creativities/folders/messages/read/${folderId}`;
        await customAxios.post(endpoint);
        dispatch(getFolderMessages(folderId));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const deleteFormatMessage =
  (messageId: number, formatId: number): AppThunk =>
  async dispatch => {
    try {
      await customAxios.delete(`creativities/formats/messages/${messageId}`);

      dispatch(getFormatMessages(formatId));
    } catch (err) {
      console.log(err);
    }
  };

export const deleteFolderMessage =
  (messageId: number, folderId: number): AppThunk =>
  async dispatch => {
    try {
      await customAxios.delete(`creativities/folders/messages/${messageId}`);

      dispatch(getFolderMessages(folderId));
    } catch (err) {
      console.log(err);
    }
  };
