import React, { useEffect, useState } from 'react';
import css from './Phases.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from '../../../components/UI/Select/Select';
import Button from '../../../components/UI/Button/Button';
import InputDate from '../../../components/UI/InputDate/InputDate';
import Icon from '../../../components/UI/Icon/Icon';
import Alert from '../../../components/UI/Alert/Alert';
import moment from 'moment';

const Phases: React.FC<PhasesProps> = ({ changeStep, info, submit, status }) => {
  const stepsList = useSelector((state: RootState) => state.steps.steps);
  const stepsTemplates = useSelector((state: RootState) => state.steps.stepsTemplate);

  const [templateOptions, setTemplateOptions] = useState<any[]>([]);
  const [template, setTemplate] = useState<Options | undefined>();

  const [stepsOptions, setStepsOptions] = useState<Options[]>([]);
  const [steps, setSteps] = useState<PhasesForm[]>([]);
  const [errors, setErrors] = useState<string>('');

  const submitFormHandler = () => {
    if (steps.length === 0) {
      setErrors('Devi aggiungere almeno una fase');
      return;
    } else {
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].step.value === '') {
          setErrors('Completa la selezione di tutte le fasi');
          return;
        }
        // Check if previous steps have bigger date
        if (i !== 0) {
          for (let a = 0; i > a; a++) {
            if (steps[i].date < steps[a].date) {
              setErrors('Errore sulle date');
              return;
            }
          }
        }
      }
    }

    submit(steps);
  };

  // Create correctly formatting for template options array
  useEffect(() => {
    const tmp: any[] = [];
    stepsTemplates.forEach((n: any) => {
      tmp.push({ value: n.id, label: n.name, steps: n.steps });
    });
    setTemplateOptions(tmp);
  }, [stepsTemplates]);

  // Create correctly formatting for steps options array
  useEffect(() => {
    const tmp: Options[] = [];
    stepsList.forEach((n: any) => {
      tmp.push({ value: n.id, label: n.name });
    });
    setStepsOptions(tmp);
  }, [stepsList]);

  // On template selected, create steps list
  const selectTemplateHandler = (e: any) => {
    const tmp: any[] = [];
    e.steps.forEach((n: any, index: number) => {
      tmp.push({
        step: { value: n.id, label: n.name },
        // Create new date for each step, incrementing by 1 every step
        date: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * (index + 1)),
      });
    });
    setTemplate(e);
    setSteps(tmp);
  };

  // Change date of step
  const changeDateHandler = (i: number, newDate: Date) => {
    const newSteps = [...steps];
    newSteps[i].date = newDate;
    setSteps(newSteps);
  };

  // Change value (name) of step
  const changeStepHandler = (i: number, newValue: Options) => {
    const newSteps = [...steps];
    newSteps[i].step = newValue;
    setSteps(newSteps);
  };

  // Remove step
  const removeStepHandler = (i: number) => {
    const newSteps = [...steps];
    newSteps.splice(i, 1);
    setSteps(newSteps);
  };

  // Add new step below
  const addStepHandler = () => {
    const newSteps = [...steps];
    const item = { step: { value: '', label: '' }, date: new Date() };
    newSteps.push(item);
    setSteps(newSteps);
  };

  // Drag steps list
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const orderedSteps: any = reorder(steps, result.source.index, result.destination.index);
    setSteps(orderedSteps);
  };

  return (
    <div>
      <div className={css.titleBar}>
        <h3>Fasi della creatività "{info.name}"</h3>
        <div className={css.titleBarIcons}>
          <span onClick={() => addStepHandler()}>
            <Icon icon='add-circle' gray></Icon>
          </span>
          <div style={{ width: 200, marginLeft: 12 }}>
            <Select
              name='template'
              options={templateOptions}
              value={template}
              placeholder='Template di fase'
              onChange={(values: Options) => selectTemplateHandler(values)}
            />
          </div>
        </div>
      </div>

      <div className={css.errorsWrapper}>
        {errors && <Alert message={errors} />}
        {status.error && <Alert message={status.error} />}
      </div>

      <div className={css.subTitleBar}>
        <span className={css.titlePhaseName}>Fase</span>
        <span>Data chiusura prevista</span>
        <span className={css.titleDays}>Giorni</span>
      </div>

      <div className={css.content}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list'>
            {(provided: any) => (
              <ul className={css.stepsList} ref={provided.innerRef} {...provided.droppableProps}>
                {steps.map((step: any, index) => (
                  <Draggable draggableId={`step${index}`} index={index} key={index}>
                    {provided => (
                      <li
                        className={css.stepsItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <span {...provided.dragHandleProps}>
                          <Icon icon='drag-drop' gray></Icon>
                        </span>
                        <div className={css.phaseName}>
                          <Select
                            name={`step${index}`}
                            options={stepsOptions}
                            onChange={(newValue: Options) => changeStepHandler(index, newValue)}
                            value={step.step}
                          />
                        </div>
                        <div className={css.inputDate}>
                          <InputDate
                            name={`date${index}`}
                            onChange={(newDate: Date) => changeDateHandler(index, newDate)}
                            dateFormat='dd/MM/yyyy'
                            minDate={new Date()}
                            selected={step.date}
                          />
                        </div>

                        <div className={css.phaseDays}>
                          <p>
                            {moment(step.date)
                              .startOf('day')
                              .diff(
                                moment(index - 1 > -1 ? steps[index - 1].date : new Date()).startOf(
                                  'day',
                                ),
                                'days',
                              )}
                            giorni
                          </p>
                        </div>
                        <div className={css.options}>
                          <span onClick={() => removeStepHandler(index)}>
                            <Icon icon='trash' gray />
                          </span>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <div className={css.footer}>
          <span style={{ marginRight: 10 }} onClick={() => changeStep()}>
            <Button text='Annulla' styleTransparent />
          </span>
          <Button
            text='Crea nuova creatività'
            onClick={() => submitFormHandler()}
            inLoad={status.loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Phases;
