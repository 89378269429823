import React, { useEffect } from 'react';
import useCheckAuth from '../hooks/useCheckAuth';
import useCheckLocalStorage from '../hooks/useCheckLocalStorage';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { newNotification } from '../store/notifications/slice';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import Dashboard from '../views/Dashboard/Dashboard';
import LostPassword from '../views/LostPassword/LostPassword';
import PageNotFound from '../views/PageNotFound/PageNotFound';
import SignIn from '../views/SignIn/SignIn';
import SignUp from '../views/SignUp/SignUp';
import Profile from '../views/Profile/Profile';
import EditProfile from '../views/EditProfile/EditProfile';
import NewCampaign from '../views/NewCampaign/NewCampaign';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import Campaign from '../views/Campaign/Campaign';
import NewCreativity from '../views/NewCreativity/NewCreativity';
import Creativity from '../views/Creativity/Creativity';
import pusher from '../utils/pusher';
import { RootState } from '../store/store';
import EditCampaign from '../views/EditCampaign/EditCampaign';
import EditCreativity from '../views/EditCreativity/EditCreativity';
import Preview from '../views/Creativity/Formats/Format/Preview/Preview';

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  useCheckLocalStorage();
  const isAuth = useCheckAuth();

  const userId = useSelector((state: RootState) => state.user.user.id);
  const token = useSelector((state: RootState) => state.user.token);

  useEffect(() => {
    if (token && userId) {
      /* pusher(token)
        .channel('notification' + userId)
        .listen('NotificationEvent', (data: any) => {
          dispatch(newNotification(data.notification));
        }); */

      const channel = pusher(token).subscribe('notification' + userId);
      channel.bind('App\\Events\\NotificationEvent', (data: any) => {
        dispatch(newNotification(data.notification));
      });

      return () => {
        channel.unbind('App\\Events\\NotificationEvent');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId]);

  return (
    <Router>
      <Switch>
        <Route path='/' exact render={() => <Redirect to='signin' />} />
        <Route
          path='/signin'
          exact
          render={() => (isAuth ? <Redirect to='/dashboard' /> : <SignIn />)}
        />
        <Route
          path='/verify/:token'
          exact
          render={() => (isAuth ? <Redirect to='/dashboard' /> : <SignUp />)}
        />
        <Route
          path='/lostpassword'
          exact
          render={() => (isAuth ? <Redirect to='/dashboard' /> : <LostPassword />)}
        />
        <Route
          path='/resetpassword/:token'
          exact
          render={() => (isAuth ? <Redirect to='/dashboard' /> : <ResetPassword />)}
        />

        <PrivateRoute path='/dashboard' exact component={Dashboard} />
        <PrivateRoute path='/newcampaign' exact component={NewCampaign} />
        <PrivateRoute path='/campaign/:campaignId' exact component={Campaign} />
        <PrivateRoute path='/editcampaign' exact component={EditCampaign} />
        <PrivateRoute
          path='/campaign/:campaignId/creativity/:creativityId/folder/:folderId'
          exact
          component={Creativity}
        />
        <PrivateRoute
          path='/campaign/:campaignId/creativity/:creativityId/folder/:folderId/preview/:formatId'
          exact
          component={Preview}
        />
        <PrivateRoute path='/editcreativity' exact component={EditCreativity} />
        <PrivateRoute path='/newcreativity' exact component={NewCreativity} />
        <PrivateRoute path='/profile' exact component={Profile} />
        <PrivateRoute path='/editprofile' exact component={EditProfile} />

        <Route path='/' component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
