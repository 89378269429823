import React, { useEffect, useState } from 'react';
import css from './NewCreativity.module.scss';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getSteps, getStepsTemplate } from '../../store/steps/api';
import { postAddCreativity } from '../../store/creativity/api';
import { clearAddCreativityCompleted } from '../../store/creativity/slice';
import Info from './Info/Info';
import Phases from './Phases/Phases';
import Topbar from '../../components/Topbar/Topbar';
import Icon from '../../components/UI/Icon/Icon';

const NewCreativity: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const status = useSelector((state: RootState) => state.creativity.add);

  const [step, setStep] = useState<number>(1);
  const [info, setInfo] = useState<InfoForm>({
    name: '',
    type: { label: '', value: '' },
    endDate: null,
  });

  useEffect(() => {
    dispatch(getSteps());
    dispatch(getStepsTemplate());
  }, [dispatch]);

  useEffect(() => {
    if (status.completed) {
      history.push(`/campaign/${campaign.id}`);
      dispatch(clearAddCreativityCompleted());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.completed]);

  useEffect(() => {
    if (!campaign.id) {
      history.push(`/dashboard`);
    }
  }, [campaign.id, history]);

  const addCreativityHandler = (data: PhasesForm[]) => {
    dispatch(postAddCreativity({ data, ...info }));
  };

  return (
    <div>
      <div>
        <Topbar title={campaign.name}></Topbar>
      </div>
      <div className={css.topTitle}>
        <h3>Creazione nuova creatività</h3>
      </div>

      <div className={css.wrapper}>
        <ul className={css.steps}>
          <li className={cx(css.stepOne, css.active)}>
            <span>{step === 2 ? <Icon icon='check' light /> : '1'}</span>
            Informazioni creatività
          </li>
          <li className={cx({ [css.active]: step === 2 })}>
            <span>2</span>Configurazione fasi
          </li>
        </ul>
        <div className={css.box}>
          {step === 1 && (
            <Info
              info={info}
              updateInfo={(info: InfoForm) => setInfo(info)}
              changeStep={() => setStep(2)}
            />
          )}
          {step === 2 && (
            <Phases
              info={info}
              changeStep={() => setStep(1)}
              submit={(data: PhasesForm[]) => addCreativityHandler(data)}
              status={status}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCreativity;
