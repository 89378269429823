import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import {
  postEditUserSuccess,
  postEditUserError,
  postEditUserLoading,
  postAddUserLoading,
  postAddUserSuccess,
  postAddUserError,
} from './slice';
import { getUserSuccess } from './../user/slice';
import { getUsers } from '../users/api';

export const getUser = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/users/profile');
    dispatch(getUserSuccess(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const postEditUser =
  (data: ProfileFormTypes): AppThunk =>
  async dispatch => {
    try {
      dispatch(postEditUserLoading());
      const res = await customAxios.post('/users/updateProfile', data);
      dispatch(postEditUserSuccess(res.data.user));
    } catch (err) {
      dispatch(postEditUserError(err.response.data.error));
    }
  };

export const postAddUser =
  (data: addUserTypes): AppThunk =>
  async (dispatch, getState) => {
    try {
      const agency_id = getState().company.company.agency_id;
      dispatch(postAddUserLoading());
      const res = await customAxios.post('/users/register', { ...data, agency_id });
      dispatch(postAddUserSuccess(res.data.user));
      dispatch(getUsers());
    } catch (err) {
      dispatch(postAddUserError(err.response.data.error));
    }
  };
