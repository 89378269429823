import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';

import {
  sendForApprovalSuccess,
  sendForApprovalLoading,
  sendForApprovalError,
  changeApprovalStatusSuccess,
  changeApprovalStatusLoading,
  changeApprovalStatusError,
} from './slice';
import { getFormats } from '../formats/api';

export const postSendForApproval =
  (
    format_ids: number[],
    format_version_ids: number[],
    approvers: number[],
    message: string,
    folderId: number,
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(sendForApprovalLoading());
      await customAxios.post('creativities/formats/sendForApproval', {
        format_ids,
        format_version_ids,
        approvers,
        message,
      });
      dispatch(sendForApprovalSuccess());
      dispatch(getFormats(folderId));
    } catch (err) {
      dispatch(sendForApprovalError(err.response.data.error));
    }
  };

export const changeApprovalStatus =
  (approvalId: number, folderId: number, status: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(changeApprovalStatusLoading(approvalId));
      await customAxios.post(`/creativities/formats/approve/${approvalId}`, { status });
      dispatch(changeApprovalStatusSuccess(approvalId));
      dispatch(getFormats(folderId, false));
    } catch (err) {
      dispatch(changeApprovalStatusError(err.response.data.error));
    }
  };

export const changeApprovalMultipleStatus =
  (approvers: any, folderId: number, status: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(changeApprovalStatusLoading('multiple'));

      await Promise.all(
        approvers.map((approvalId: number) =>
          customAxios.post(`/creativities/formats/approve/${approvalId}`, { status }),
        ),
      );

      dispatch(getFormats(folderId, false));
      dispatch(changeApprovalStatusSuccess('multiple'));
    } catch (err) {
      dispatch(changeApprovalStatusError(err.response.data.error));
    }
  };
