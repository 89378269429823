import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import {
  postAuthLoading,
  postAuthSuccess,
  postAuthError,
  postRegistrationLoading,
  postRegistrationSuccess,
  postRegistrationError,
  getTempUserLoading,
  getTempUserSuccess,
  getTempUserError,
  postCompleteRegistrationSuccess,
  postLostPasswordLoading,
  postLostPasswordSuccess,
  postLostPasswordError,
  postResetPasswordLoading,
  postResetPasswordSuccess,
  postResetPasswordError,
} from './slice';
import { getUserSuccess } from './../user/slice';

export const postAuth =
  (data: SignInForm): AppThunk =>
  async dispatch => {
    try {
      dispatch(postAuthLoading());
      const res = await customAxios.post('/users/auth', data);
      dispatch(postAuthSuccess());
      dispatch(getUserSuccess(res.data));
      //if (data.remember) {
      localStorage.setItem('token', res.data.token);
      // localStorage.setItem('exp', res.data.remember);
      //}
    } catch (err: any) {
      dispatch(postAuthError(err.response.data.error));
    }
  };

export const postRegistration =
  (data: RegistrationForm): AppThunk =>
  async dispatch => {
    try {
      dispatch(postRegistrationLoading());
      await customAxios.post('/users/register', data);
      dispatch(postRegistrationSuccess());
    } catch (err) {
      dispatch(postRegistrationError(err.response.data.error));
    }
  };

export const postLostPassword =
  (data: LostPasswordForm): AppThunk =>
  async dispatch => {
    try {
      dispatch(postLostPasswordLoading());
      await customAxios.post('/users/forgotpass', data);
      dispatch(postLostPasswordSuccess());
    } catch (err) {
      dispatch(postLostPasswordError(err.response.data.error));
    }
  };

export const postResetPassword =
  (data: ResetPasswordForm, token: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(postResetPasswordLoading());
      await customAxios.post('/users/resetpass', { ...data, token });
      dispatch(postResetPasswordSuccess());
    } catch (err) {
      dispatch(postResetPasswordError(err.message));
    }
  };

export const postRefreshToken = (): AppThunk => async dispatch => {
  try {
    await customAxios.post('/users/refreshToken');
  } catch (err) {
    console.log(err);
  }
};

export const getVerifyToken =
  (token: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getTempUserLoading());
      const res = await customAxios.get('/users/verify', { params: { token } });
      dispatch(getTempUserSuccess(res.data.user));
    } catch (err) {
      dispatch(getTempUserError());
    }
  };

export const postCompleteRegistration =
  (data: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(postRegistrationLoading());
      await customAxios.post('/users/verify', data);
      dispatch(postRegistrationSuccess());
      dispatch(postCompleteRegistrationSuccess());
    } catch (err) {
      dispatch(postRegistrationError(err.response.data.error));
    }
  };
