import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';

import { getCompanySuccess } from './slice';

export const getCompany = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/companies', {
      params: {
        id: 1,
      },
    });
    dispatch(getCompanySuccess(res.data));
  } catch (err) {
    console.log(err);
  }
};
