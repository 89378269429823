import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthTypes {
  error: string | null;
  loading: boolean;
}

interface TempUserTypes extends AuthTypes {
  tempUser: {
    completed: boolean;
    error: boolean;
    loading: boolean;
    user: userTypes;
  };
}

export const authStore = createSlice({
  name: 'auth',
  initialState: {
    signin: {
      error: null,
      loading: false,
    } as AuthTypes,
    signup: {
      error: null,
      loading: false,
      tempUser: {
        completed: false,
        error: false,
        loading: false,
        user: {},
      },
    } as TempUserTypes,
    lostpassword: {
      error: null,
      loading: false,
    } as AuthTypes,
    resetpassword: {
      error: null,
      loading: false,
    } as AuthTypes,
  },
  reducers: {
    // Signin
    postAuthLoading(state) {
      state.signin.loading = true;
      state.signin.error = null;
    },
    postAuthSuccess(state) {
      state.signin.loading = false;
    },
    postAuthError(state, action: PayloadAction<string>) {
      state.signin.error = action.payload;
      state.signin.loading = false;
    },

    // Signup
    postRegistrationLoading(state) {
      state.signup.error = null;
      state.signup.loading = true;
    },
    postRegistrationSuccess(state) {
      state.signup.loading = false;
    },
    postRegistrationError(state, action: PayloadAction<string>) {
      state.signup.error = action.payload;
      state.signup.loading = false;
    },
    getTempUserLoading(state) {
      state.signup.tempUser.loading = true;
    },
    getTempUserSuccess(state, action: PayloadAction<userTypes>) {
      state.signup.tempUser.loading = false;
      state.signup.tempUser.error = false;
      state.signup.tempUser.user = action.payload;
    },
    getTempUserError(state) {
      state.signup.tempUser.error = true;
      state.signup.tempUser.loading = false;
    },
    postCompleteRegistrationSuccess(state) {
      state.signup.tempUser.completed = true;
    },
    clearCompleteRegistration(state) {
      state.signup.tempUser.completed = false;
    },

    // Lost password
    postLostPasswordLoading(state) {
      state.lostpassword.loading = true;
      state.lostpassword.error = null;
    },
    postLostPasswordSuccess(state) {
      state.lostpassword.loading = false;
    },
    postLostPasswordError(state, action: PayloadAction<string>) {
      state.lostpassword.error = action.payload;
      state.lostpassword.loading = false;
    },

    // Reset password
    postResetPasswordLoading(state) {
      state.resetpassword.loading = true;
      state.resetpassword.error = null;
    },
    postResetPasswordSuccess(state) {
      state.resetpassword.loading = false;
    },
    postResetPasswordError(state, action: PayloadAction<string>) {
      state.resetpassword.error = action.payload;
      state.resetpassword.loading = false;
    },
  },
});

export const {
  postAuthLoading,
  postAuthSuccess,
  postAuthError,
  postRegistrationLoading,
  postRegistrationSuccess,
  postRegistrationError,
  getTempUserLoading,
  getTempUserSuccess,
  getTempUserError,
  postCompleteRegistrationSuccess,
  clearCompleteRegistration,
  postLostPasswordLoading,
  postLostPasswordSuccess,
  postLostPasswordError,
  postResetPasswordLoading,
  postResetPasswordSuccess,
  postResetPasswordError,
} = authStore.actions;
