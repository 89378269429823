import React, { useEffect, useState } from 'react';
import css from './CreativityInfo.module.scss';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store/store';
import moment from 'moment';
import { useIsOwner } from '../../../hooks/useRoles';
import { postAddFolder } from '../../../store/formats/api';
import { deleteCreativity, putUpdateStepCreativity } from '../../../store/creativity/api';
import {
  clearDeleteCreativity,
  clearUpdateCreativity,
  setInfoOpen,
} from '../../../store/creativity/slice';
import { clearAddFolder } from '../../../store/formats/slice';
import { useForm, Controller } from 'react-hook-form';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import Input from '../../../components/UI/Input/Input';
import Icon from '../../../components/UI/Icon/Icon';
import Select from '../../../components/UI/Select/Select';
import DropDownMenu from '../../../components/UI/DropDownMenu/DropDownMenu';
import checkStepCompleted from '../../../utils/checkSteps';

interface CreativityInfoProps {
  creativity: CreativityTypes;
  folder: FolderTypes;
  changeCurrent: (folder: FolderTypes) => void;
}

type FormTypes = { folderName: string };

const CreativityInfo: React.FC<CreativityInfoProps> = ({ creativity, folder, changeCurrent }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isIntOwner = useIsOwner();

  const addFolderStatus = useSelector((state: RootState) => state.formats.addFolder);
  const editStepStatus = useSelector((state: RootState) => state.creativity.edit);
  const deleteStatus = useSelector((state: RootState) => state.creativity.delete);
  const infoOpen = useSelector((state: RootState) => state.creativity.infoOpen);

  const [currentStep, setCurrentStep] = useState<StepTypes>();
  const [isNewFolderOpen, setIsNewFolderOpen] = useState<boolean>(false);
  const [stepOptions, setStepOptions] = useState<Options[]>([]);
  const [stepSelect, setStepSelect] = useState<Options>({ label: '', value: '' });
  const [isStepChange, setIsStepChange] = useState<any>({ status: false, newStep: null });
  const [deleteModal, setDeleteModal] = useState(false);

  const { control, errors, handleSubmit } = useForm<FormTypes>();

  const onSubmit = (data: FormTypes) => {
    dispatch(postAddFolder({ name: data.folderName, creativityId: creativity.id }));
  };

  useEffect(() => {
    if (creativity.steps) {
      const current = creativity.steps.find((step: StepTypes) => step.status === 'current');
      if (current) {
        setCurrentStep(current);
        setStepSelect({ label: current.name, value: String(current.id) });
      }

      const options: any[] = [];
      creativity.steps.forEach(step => {
        options.push({ label: step.name, value: step.id });
      });
      setStepOptions(options);
    }
  }, [creativity.steps]);

  useEffect(() => {
    if (addFolderStatus.completed) {
      setIsNewFolderOpen(false);
      dispatch(clearAddFolder());
    }
  }, [addFolderStatus.completed, dispatch]);

  useEffect(() => {
    if (editStepStatus.completed) {
      setIsStepChange({ status: false, newStep: null });
      dispatch(clearUpdateCreativity());
    }
  }, [editStepStatus.completed, dispatch]);

  const updatePhaseHandler = (newStep: Options) => {
    dispatch(
      putUpdateStepCreativity({
        id: creativity.id,
        name: creativity.name,
        creativity_type: creativity.creativity_type,
        step_id: newStep.value,
      }),
    );
  };

  const deleteCreativityHandler = () => {
    dispatch(deleteCreativity(creativity.id));
  };

  useEffect(() => {
    if (deleteStatus.completed) {
      dispatch(clearDeleteCreativity());
      history.push(`/campaign/${creativity.campaign_id}`);
    }
  }, [creativity.campaign_id, deleteStatus.completed, dispatch, history]);

  return (
    <>
      <div className={cx(css.wrapper, { [css.infoOpen]: infoOpen })}>
        <div className={css.row}>
          <h3>{creativity.name}</h3>

          {isIntOwner && (
            <DropDownMenu
              menuItems={[
                {
                  label: 'Modifica creatività',
                  icon: 'edit',
                  onClick: () => history.push('/editcreativity'),
                },
                {
                  label: 'Elimina creatività',
                  icon: 'trash',
                  onClick: () => setDeleteModal(true),
                },
              ]}
            />
          )}
        </div>
        <div className={css.contentWrapper}>
          <div className={css.stepsWrapper}>
            <span>Fasi</span>

            {isIntOwner ? (
              <Select
                name='step'
                options={stepOptions}
                value={stepSelect}
                onChange={(newStep: Options) =>
                  newStep.label !== stepSelect.label
                    ? setIsStepChange({ status: true, newStep: newStep })
                    : null
                }
              />
            ) : (
              <p>{currentStep?.name}</p>
            )}

            <div className={css.stepsList}>
              {creativity.steps?.map(step => (
                <span
                  key={step.id}
                  className={cx({
                    [css.completed]: checkStepCompleted(step, currentStep),
                  })}
                />
              ))}
            </div>
            <div className={css.stepsDeadline}>
              {currentStep && (
                <span>
                  {moment(currentStep?.endDate).diff(moment(new Date()), 'days')}gg alla nuova fase
                </span>
              )}
              <span>
                Online il{' '}
                {creativity.steps?.slice(-1)[0] &&
                  moment(creativity.steps?.slice(-1)[0].endDate).format('DD.MM.yyyy')}
              </span>
            </div>
          </div>

          <ul className={css.formatsFolders}>
            {creativity.folders?.map((f: FolderTypes) => (
              <li
                key={f.id}
                className={cx({ [css.current]: f.id === folder.id })}
                onClick={() => {
                  changeCurrent(f);
                  dispatch(setInfoOpen(false));
                }}
              >
                <div className={css.folderInfo}>
                  <Icon icon='format' primary />
                  <div>{f.name}</div>
                </div>
                <div className={css.folderMeta}>
                  <span className={css.muted}>Nessun formato caricato (test)</span>
                </div>
              </li>
            ))}
          </ul>

          {isIntOwner && (
            <span className={css.newFolder}>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                icon={{ name: 'add-circle' }}
                styleTransparent
                fullwidth
                dashed
                text='Crea nuovo gruppo formati'
                onClick={() => setIsNewFolderOpen(true)}
              />
            </span>
          )}
        </div>
      </div>
      <Modal
        title='Crea nuovo gruppo formati'
        isOpen={isNewFolderOpen}
        onClose={() => setIsNewFolderOpen(false)}
        submitButton={{ text: 'Crea nuova cartella', inLoad: addFolderStatus.loading }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={css.form}>
          <Controller
            name='folderName'
            as={Input}
            control={control}
            defaultValue=''
            rules={{ required: 'Il nome è obbligatorio' }}
            label='Nome cartella <span>*</span>'
            error={errors.folderName ? 1 : 0}
          />
          {errors.folderName && <span className={css.error}>{errors.folderName.message}</span>}
        </div>
      </Modal>

      <Modal
        title='Cambio fase'
        isOpen={isStepChange.status}
        onClose={() => setIsStepChange(false)}
        submitButton={{ text: 'Conferma', inLoad: editStepStatus.loading }}
        onSubmit={() => updatePhaseHandler(isStepChange.newStep)}
      >
        <div className={css.updateStepInfo}>
          <p>
            Stai modificando la fase da {stepSelect.label} a {isStepChange.newStep?.label}
          </p>
        </div>
      </Modal>

      <Modal
        title='Eliminazione creatività'
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        submitButton={{ text: 'Conferma', type: 'danger', inLoad: deleteStatus.loading }}
        onSubmit={deleteCreativityHandler}
      >
        <div className={css.deleteInfo}>
          <div className={css.deleteIcon}>
            <Icon icon='trash' gray />
          </div>
          <p>
            Sei sicuro di voler eliminare la creatività <strong>"{creativity.name}"</strong>?
          </p>
        </div>

        <div className={css.operationsInfo}>
          <p>Tutte le seguenti operazioni non potranno essere ripristinati</p>

          <div>
            <ul>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i formati del gruppo verranno eliminati
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti gli allegati e i commenti verranno eliminati
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i link di condivisione non saranno raggiungibili
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreativityInfo;
