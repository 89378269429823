import React from 'react';
import useCheckAuth from '../../hooks/useCheckAuth';
import { Route, Redirect } from 'react-router-dom';
import BaseLayout from '../BaseLayout/BaseLayout';

interface PrivateRouteProps {
  component: React.FC<any>;
  exact?: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const isAuth = useCheckAuth();

  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <BaseLayout>
            <Component {...props} />
          </BaseLayout>
        ) : (
          <Redirect to='/signin' />
        )
      }
    />
  );
};

export default PrivateRoute;
