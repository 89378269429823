import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './ApprovalManager.module.scss';
import cx from 'classnames';
import ApprovalStatus from './ApprovalStatus/ApprovalStatus';
import {
  changeApprovalMultipleStatus,
  changeApprovalStatus,
} from '../../../../../store/approval/api';
import ApprovalPopup from './ApprovalPopup/ApprovalPopup';
import { RootState } from '../../../../../store/store';
import { useIsExternalOwner, useIsOwner } from '../../../../../hooks/useRoles';
import Icon from '../../../../../components/UI/Icon/Icon';
import Modal from '../../../../../components/UI/Modal/Modal';
import Avatar from '../../../../../components/Avatar/Avatar';
import DropDown from '../../../../../components/UI/DropDown/DropDown';
import Button from '../../../../../components/UI/Button/Button';
import {
  deleteApprovalRequest,
  deleteMultipleApprovaRequest,
} from '../../../../../store/formats/api';

interface ApprovalManagerProps {
  selectedFormats?: any[];
  folderId: any;
  approvals: any;
  haveToApproveButton?: boolean;
  format?: any;
  sendForApproval?: () => void;
  showSendForApprovalButton: boolean;
}

const ApprovalManager: React.FC<ApprovalManagerProps> = ({
  selectedFormats,
  folderId,
  approvals,
  sendForApproval,
  showSendForApprovalButton,
  format,
}) => {
  const dispatch = useDispatch();
  const isOwner = useIsOwner();
  const isExtOwner = useIsExternalOwner();

  const approveStatus = useSelector((state: RootState) => state.approval.edit);
  const currentUser = useSelector((state: RootState) => state.user.user);

  const [approvalPopupOpen, setApprovalPopupOpen] = useState<boolean>(false);
  const [extApprovers, setExtApprovers] = useState<any[]>([]);
  const [haveToApprove, setHaveToApprove] = useState<any>({ exist: false, status: '', id: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);

  // Check external approvers
  useEffect(() => {
    if (approvals.length > 0) {
      const tempApprovers: any[] = [];

      approvals.forEach((el: any) => {
        if (el.user.designation === 'client') {
          tempApprovers.push(el);
        }
      });

      if (tempApprovers.length > 0) {
        setExtApprovers(tempApprovers);
      } else {
        setExtApprovers([]);
      }
    }
  }, [approvals]);

  useEffect(() => {
    if (approvals.length > 0) {
      const haveToApprove = approvals.find((el: any) => el.user.id === currentUser.id);

      if (haveToApprove) {
        setHaveToApprove({ exist: true, status: haveToApprove.status, id: haveToApprove.id });
      }
    } else {
      setHaveToApprove({ exist: false, status: '', id: null });
    }
  }, [currentUser.id, approvals]);

  const changeApprovalStatusHandler = (status: string, approvalId: number) => {
    if (selectedFormats) {
      const approvalsList: any[] = [];

      selectedFormats.forEach((selectedFormat: any) => {
        const find = selectedFormat.approvals.find(
          (approver: any) => approver.user.id === approvalId,
        ).id;

        approvalsList.push(find);
      });

      dispatch(changeApprovalMultipleStatus(approvalsList, folderId, status));
    } else {
      dispatch(changeApprovalStatus(approvalId, folderId, status));
    }
  };

  const deleteApprovalRequestHandler = (app: any) => {
    if (selectedFormats) {
      const approvalsList: any[] = [];

      selectedFormats.forEach((selectedFormat: any) => {
        const find = selectedFormat.approvals.find(
          (approver: any) => approver.user.id === app.approval_id,
        );
        approvalsList.push({
          approval_id: find.id,
          user_id: find.user.id,
          format_id: selectedFormat.format,
        });
      });
      dispatch(deleteMultipleApprovaRequest(approvalsList, folderId));
    } else {
      dispatch(deleteApprovalRequest(app, folderId));
    }
  };

  return (
    <>
      <div className={css.left}>
        <ApprovalStatus
          haveToApprove={haveToApprove}
          approvals={approvals}
          extApprovers={extApprovers}
        />
        {approvals.length > 0 && (
          <div className={css.currentApproverWrap}>
            {extApprovers.length === 0 ? (
              <>
                {approvals.map((el: any) => (
                  <React.Fragment key={el.user.id}>
                    {el.user.id === currentUser.id && (
                      <ApprovalPopup
                        approver={el}
                        canApprove={{ status: true }}
                        approveLoading={
                          selectedFormats
                            ? approveStatus.loading
                              ? true
                              : false
                            : approveStatus.loading === el.id
                        }
                        multiple={selectedFormats ? true : false}
                        changedStatus={(newStatus: string) =>
                          changeApprovalStatusHandler(
                            newStatus,
                            selectedFormats ? el.user.id : el.id,
                          )
                        }
                        deleteApprovalRequest={() =>
                          deleteApprovalRequestHandler(
                            selectedFormats
                              ? { approval_id: el.user.id }
                              : { approval_id: el.id, user_id: el.user.id, format_id: format.id },
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                {extApprovers.map((extApprover: any) => (
                  <React.Fragment key={extApprover.user.id}>
                    {extApprover.user.id === currentUser.id && (
                      <ApprovalPopup
                        approver={extApprover}
                        canApprove={{ status: true }}
                        approveLoading={
                          selectedFormats
                            ? approveStatus.loading
                              ? true
                              : false
                            : approveStatus.loading === extApprover.id
                        }
                        multiple={selectedFormats ? true : false}
                        changedStatus={(newStatus: string) =>
                          changeApprovalStatusHandler(
                            newStatus,
                            selectedFormats ? extApprover.user.id : extApprover.id,
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        )}

        {approvals.length > 10 ? (
          <div className={css.otherApproverWrap}>
            <div onClick={() => setIsModalOpen(true)}>
              <Icon icon='add-circle' gray />
            </div>
          </div>
        ) : (
          <div className={css.notCurrentApproverWrap}>
            {extApprovers.length === 0 ? (
              <>
                {approvals.map((el: any) => (
                  <React.Fragment key={el.user.id}>
                    {el.user.id !== currentUser.id && (
                      <ApprovalPopup
                        approver={el}
                        canApprove={{ status: isOwner, isOwner: isOwner }}
                        approveLoading={
                          selectedFormats
                            ? approveStatus.loading
                              ? true
                              : false
                            : approveStatus.loading === el.id
                        }
                        multiple={selectedFormats ? true : false}
                        changedStatus={(newStatus: string) =>
                          changeApprovalStatusHandler(
                            newStatus,
                            selectedFormats ? el.user.id : el.id,
                          )
                        }
                        deleteApprovalRequest={() =>
                          deleteApprovalRequestHandler(
                            selectedFormats
                              ? { approval_id: el.user.id }
                              : { approval_id: el.id, user_id: el.user.id, format_id: format.id },
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                {extApprovers.map((extApprover: any) => (
                  <React.Fragment key={extApprover.user.id}>
                    {extApprover.user.id !== currentUser.id && (
                      <ApprovalPopup
                        approver={extApprover}
                        canApprove={{ status: isExtOwner, isOwner: isExtOwner }}
                        approveLoading={
                          selectedFormats
                            ? approveStatus.loading
                              ? true
                              : false
                            : approveStatus.loading === extApprover.id
                        }
                        multiple={selectedFormats ? true : false}
                        changedStatus={(newStatus: string) =>
                          changeApprovalStatusHandler(
                            newStatus,
                            selectedFormats ? extApprover.user.id : extApprover.id,
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        )}
        {showSendForApprovalButton && (
          <div className={css.otherApproverWrap}>
            <div onClick={sendForApproval}>
              <Icon icon='add-circle' gray />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ApprovalManager;
