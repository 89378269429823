import React from 'react';
import css from './Textarea.module.scss';
import cx from 'classnames';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  label?: string;
}

const Textarea = React.forwardRef((props: InputProps, ref: React.Ref<HTMLTextAreaElement>) => {
  return (
    <label>
      {props.label}
      <textarea className={cx(css.textarea, 'textarea')} ref={ref} {...props} />
    </label>
  );
});

export default Textarea;
