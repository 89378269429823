import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import css from './ChatManager.module.scss';
import ChatList from '../ChatList/ChatList';
import pusher from '../../../utils/pusher';
import Chat from '../Chat/Chat';
import { RootState } from '../../../store/store';
import { getFolderMessages, getFormatMessages } from '../../../store/chat/api';
import { getNewFolderMessage, getNewFormatMessage } from '../../../store/chat/slice';
import { useIsExternalMember } from '../../../hooks/useRoles';

interface ChatManagerProps {
  folder: FolderTypes;
  changeSelectedChat: number | null;
}

const ChatManager: React.FC<ChatManagerProps> = ({ folder, changeSelectedChat }) => {
  const dispatch = useDispatch();

  const isExtMember = useIsExternalMember();

  const folderMessages = useSelector((state: RootState) => state.chat.get.messages);
  const token = useSelector((state: RootState) => state.user.token);

  const formats = useSelector((state: RootState) => state.formats.formats);
  const formatMessages = useSelector((state: RootState) => state.chat.get.formatMessages);

  useEffect(() => {
    if (formats) {
      formats.forEach((format: any) => {
        if (!formatMessages.find((fm: any) => fm.id === format.id)) {
          dispatch(getFormatMessages(format.id));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formats]);

  const [selectedChat, setSelectedChat] = useState<null | number>(null);
  const [chatType, setChatType] = useState<'internal' | 'external'>('internal');

  useEffect(() => {
    if (isExtMember) {
      setChatType('external');
    }
  }, [isExtMember]);

  useEffect(() => {
    if (folder.id) {
      dispatch(getFolderMessages(folder.id));
    }
  }, [dispatch, folder.id]);

  const getNewMessageHandler = (data: any) => {
    if (data.data.creativities_format_id === 0) {
      if (data.data.creativity_folder_id === folder.id) {
        dispatch(getNewFolderMessage(data.data));
      }
    } else {
      dispatch(
        getNewFormatMessage({ formatId: data.data.creativities_format_id, data: data.data }),
      );
    }
  };

  useEffect(() => {
    if (token) {
      const channel = pusher(token).subscribe('message');
      channel.bind('App\\Events\\NewMessageEvent', getNewMessageHandler);

      return () => {
        channel.unbind('App\\Events\\NewMessageEvent');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder]);

  useEffect(() => {
    if (changeSelectedChat !== null) {
      setSelectedChat(changeSelectedChat);
    }
  }, [changeSelectedChat]);

  return (
    <div className={cx(css.wrapper, 'chat-manager')}>
      {selectedChat === null ? (
        <ChatList
          onSelected={(chatId: number) => setSelectedChat(chatId)}
          formatMessages={formatMessages}
          chatType={chatType}
          changeChatType={type => setChatType(type)}
        />
      ) : (
        <>
          {chatType === 'internal' && (
            <Chat
              selectedChat={selectedChat}
              folder={folder}
              messages={
                selectedChat === 0
                  ? folderMessages.filter(
                      (m: any) => m.destination_chat === 'internal' || m.type === 'system',
                    )
                  : formatMessages.length > 0 &&
                    formatMessages
                      .find((fm: any) => fm.id === selectedChat)
                      .messages.filter(
                        (m: any) => m.destination_chat === 'internal' || m.type === 'system',
                      )
                  ? formatMessages
                      .find((fm: any) => fm.id === selectedChat)
                      .messages.filter(
                        (m: any) => m.destination_chat === 'internal' || m.type === 'system',
                      )
                  : []
              }
              onClose={() => setSelectedChat(null)}
              chatType={chatType}
              changeChatType={type => setChatType(type)}
            />
          )}

          {chatType === 'external' && (
            <Chat
              selectedChat={selectedChat}
              folder={folder}
              messages={
                selectedChat === 0
                  ? folderMessages.filter((m: any) => m.destination_chat === 'external')
                  : formatMessages.length > 0 &&
                    formatMessages
                      .find((fm: any) => fm.id === selectedChat)
                      .messages.filter((m: any) => m.destination_chat === 'external')
                  ? formatMessages
                      .find((fm: any) => fm.id === selectedChat)
                      .messages.filter((m: any) => m.destination_chat === 'external')
                  : []
              }
              onClose={() => setSelectedChat(null)}
              chatType={chatType}
              changeChatType={type => setChatType(type)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChatManager;
