import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import { getNotificationsSuccess } from './slice';

export const getNotifications = (): AppThunk => async dispatch => {
  try {
    const res = await customAxios.get('/users/notifications?limit=100');
    dispatch(getNotificationsSuccess(res.data.notifications));
  } catch (err) {
    console.log(err);
  }
};

export const hasReadNotifications =
  (list: any[]): AppThunk =>
  async dispatch => {
    try {
      await Promise.all(
        list.map((notificationId: number) =>
          customAxios.put(`/users/notifications/${notificationId}`, { read: 1 }),
        ),
      );
      dispatch(getNotifications());
    } catch (err) {
      console.log(err);
    }
  };
