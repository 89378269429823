import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const campaignStore = createSlice({
  name: 'campaign',
  initialState: {
    add: {
      loading: false,
      error: null,
      completed: false,
    },
    edit: {
      loading: false,
      error: null,
      completed: false,
    },
    delete: {
      loading: false,
      completed: false,
    },
    get: {
      loading: false,
      error: null,
    },
    campaign: {},
  } as campaignStoreTypes,
  reducers: {
    // Get campaign info
    getCampaignLoading(state) {
      state.get.loading = true;
    },
    getCampaignSuccess(state, action: PayloadAction<CampaignTypes>) {
      state.get.loading = false;
      state.campaign = action.payload;
    },
    getCampaignError(state, action: PayloadAction<string>) {
      state.get.error = action.payload;
      state.get.loading = false;
    },

    // Add campaign
    postAddCampaignLoading(state) {
      state.add.loading = true;
      state.add.error = null;
    },
    postAddCampaignSuccess(state) {
      state.add.loading = false;
      state.add.error = null;
      state.add.completed = true;
    },
    postAddCampaignError(state, action: PayloadAction<string>) {
      state.add.loading = false;
      state.add.error = action.payload;
    },
    clearAddCampaignCompleted(state) {
      state.add.completed = false;
    },

    // Edit campaign
    postEditCampaignLoading(state) {
      state.edit.loading = true;
    },
    postEditCampaignSuccess(state) {
      state.edit.loading = false;
      state.edit.completed = true;
    },
    postEditCampaignError(state, action: PayloadAction<string>) {
      state.edit.loading = false;
      state.edit.error = action.payload;
    },
    clearEditCampaignCompleted(state) {
      state.edit.completed = false;
    },

    // Delete campaign
    deleteCampaignLoading(state) {
      state.delete.loading = true;
    },
    deleteCampaignSuccess(state) {
      state.delete.loading = false;
      state.delete.completed = true;
    },
    clearDeleteCampaign(state) {
      state.delete.completed = false;
    },
  },
});

export const {
  getCampaignLoading,
  getCampaignSuccess,
  getCampaignError,
  postAddCampaignLoading,
  postAddCampaignSuccess,
  postAddCampaignError,
  clearAddCampaignCompleted,
  postEditCampaignLoading,
  postEditCampaignSuccess,
  postEditCampaignError,
  clearEditCampaignCompleted,
  deleteCampaignLoading,
  deleteCampaignSuccess,
  clearDeleteCampaign,
} = campaignStore.actions;
