import React from 'react';
import Icon from '../UI/Icon/Icon';
import styled from 'styled-components';

interface AvatarProps {
  user: any;
  size?: number;
}

const AvatarWrapper: any = styled.div`
  background-color: #d6e2e9;
  width: ${(props: any) => (props.size ? props.size : 30)}px;
  height: ${(props: any) => (props.size ? props.size : 30)}px;
  border-radius: 50%;
  color: black;
  position: relative;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #fff6cc;
    position: absolute;
    right: -10px;
    bottom: -1px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 8.5px;
      height: 8.9px;
    }
  }
`;

const Avatar: React.FC<AvatarProps> = ({ user, size }) => {
  return (
    <AvatarWrapper size={size}>
      <p>{user?.name[0]}</p>

      {user?.owner && (
        <span>
          <Icon icon='ownership' orange />
        </span>
      )}
    </AvatarWrapper>
  );
};

export default Avatar;
