import React, { useState } from 'react';
import css from './AddFormatEditor.module.scss';
import Button from '../../../../components/UI/Button/Button';
import Editor from '../../../../components/UI/Editor/Editor';
import Modal from '../../../../components/UI/Modal/Modal';

interface AddFormatEditorProps {
  submit: (data: AddFormatDataTypes) => void;
  cancel: () => void;
  // inLoad?: boolean;
}

const AddFormatEditor: React.FC<AddFormatEditorProps> = ({ submit, cancel /*inLoad*/ }) => {
  const [editorContent, setEditorContent] = useState<string>('');
  const [attachments, setAttachments] = useState<FileTypes[]>([]);
  const [images, setImages] = useState<FileTypes[]>([]);
  const [multipleModal, setMultipleOpen] = useState<{
    status: boolean;
    number: number;
    content: any;
  }>({
    status: false,
    number: 0,
    content: null,
  });

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
  };

  const handleAttachmentsChange = (files: any) => {
    setAttachments(files.attachments);
    setImages(files.images);
  };

  const onSubmitHandler = () => {
    // Clear string
    const content = editorContent.replaceAll('&nbsp;', ' ');

    // Match multiple link
    const multiple = content.match(/(.*?)<a[^>]*>([^<]+)<\/a>/g);

    // Check if there are multiple link
    if (!multiple) {
      const upContent = content.replaceAll(/<[^>]*>?/g, '');
      submit({ title: upContent, content: upContent, attachments, images });
    } else if (multiple.length === 1) {
      let title = multiple[0].split('<a')[0];
      title = title.replaceAll(/<[^>]*>?/g, '');
      submit({ title: title, content: content, attachments, images });
    } else {
      setMultipleOpen({ status: true, number: multiple.length, content: multiple });
    }
  };

  // Create multiple formats
  const createMultipleFormats = () => {
    multipleModal.content.forEach((el: any) => {
      let title = el.split('<a')[0];
      title = title.replaceAll(/<[^>]*>?/g, '');
      submit({ title: title, content: el, attachments, images });
    });
  };

  return (
    <>
      <div className={css.wrapper}>
        <Editor changed={handleEditorChange} updateAttachments={handleAttachmentsChange} />

        <div className={css.addFormatRow}>
          <Button text='Annulla' styleTransparent style={{ marginRight: 8 }} onClick={cancel} />

          <Button text='Aggiungi' onClick={() => onSubmitHandler()} />
        </div>
      </div>
      <Modal
        isOpen={multipleModal.status}
        title='Creazione formati'
        onClose={() => setMultipleOpen({ status: false, number: 0, content: null })}
        submitButton={{ text: 'Conferma' }}
        onSubmit={createMultipleFormats}
      >
        <div className={css.multipleModalContent}>
          Stai creando {multipleModal.number} formati, sei sicuro?
        </div>
      </Modal>
    </>
  );
};

export default AddFormatEditor;
