import React, { useEffect, useState } from 'react';
import css from '../NewCreativity/NewCreativity.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { putUpdateCreativity } from '../../store/creativity/api';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getSteps, getStepsTemplate } from '../../store/steps/api';
import { useForm, Controller } from 'react-hook-form';
import { RootState } from '../../store/store';
import moment from 'moment';
import Select from '../../components/UI/Select/Select';
import Input from '../../components/UI/Input/Input';
import Icon from '../../components/UI/Icon/Icon';
import Alert from '../../components/UI/Alert/Alert';
import InputDate from '../../components/UI/InputDate/InputDate';
import Button from '../../components/UI/Button/Button';
import { clearUpdateCreativity } from '../../store/creativity/slice';
import Topbar from '../../components/Topbar/Topbar';

const EditCreativity = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const stepsList = useSelector((state: RootState) => state.steps.steps);
  const stepsTemplates = useSelector((state: RootState) => state.steps.stepsTemplate);
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const creativity = useSelector((state: RootState) => state.creativity.creativity);
  const editStatus = useSelector((state: RootState) => state.creativity.edit);

  const [templateOptions, setTemplateOptions] = useState<any[]>([]);
  const [template, setTemplate] = useState<Options>({ value: '', label: '' });

  const [stepsOptions, setStepsOptions] = useState<Options[]>([]);
  const [steps, setSteps] = useState<PhasesForm[]>([]);
  const [stepErrors, setStepErrors] = useState<string>('');

  const { control, errors, handleSubmit, setValue } = useForm<any>();

  const submitFormHandler = (data: any) => {
    if (steps.length === 0) {
      setStepErrors('Devi aggiungere almeno una fase');
      return;
    } else {
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].step.value === '') {
          setStepErrors('Completa la selezione di tutte le fasi');
          return;
        }
        // Check if previous steps have bigger date
        if (i !== 0) {
          for (let a = 0; i > a; a++) {
            if (steps[i].date < steps[a].date) {
              setStepErrors('Errore sulle date');
              return;
            }
          }
        }
      }
    }

    const updateCreativity: any = { ...creativity };

    updateCreativity.name = data.name;
    updateCreativity.creativity_type = data.type.label;
    updateCreativity.steps = steps;

    dispatch(putUpdateCreativity(updateCreativity));
  };

  useEffect(() => {
    if (editStatus.completed) {
      dispatch(clearUpdateCreativity());
      if (creativity.folders[0]) {
        history.push(
          `/campaign/${creativity.campaign_id}/creativity/${creativity.id}/folder/${creativity.folders[0].id}`,
        );
      } else {
        history.push('/dashboard');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editStatus.completed]);

  useEffect(() => {
    if (!creativity.id) {
      history.push('/dashboard');
    }
  }, [creativity, history]);

  useEffect(() => {
    dispatch(getSteps());
    dispatch(getStepsTemplate());
  }, [dispatch]);

  useEffect(() => {
    if (creativity.name && creativity.creativity_type) {
      setValue('name', creativity.name);
      setValue('type', { value: creativity.creativity_type, label: creativity.creativity_type });
    }
    if (creativity.steps?.length > 0) {
      const oldSteps: any = [];
      creativity.steps.forEach((stp: any) => {
        oldSteps.push({
          date: moment(stp.endDate, 'YYYY-MM-DD HH:mm').toDate(),
          step: { value: 0, label: stp.name },
        });
      });
      setSteps(oldSteps);
    }
  }, [creativity, setValue]);

  // Create correctly formatting for template options array
  useEffect(() => {
    const tmp: any[] = [];
    stepsTemplates.forEach((n: any) => {
      tmp.push({ value: n.id, label: n.name, steps: n.steps });
    });
    setTemplateOptions(tmp);
  }, [stepsTemplates]);

  // Create correctly formatting for steps options array
  useEffect(() => {
    const tmp: Options[] = [];
    stepsList.forEach((n: any) => {
      tmp.push({ value: n.id, label: n.name });
    });
    setStepsOptions(tmp);
  }, [stepsList]);

  // On template selected, create steps list
  const selectTemplateHandler = (e: any) => {
    const tmp: any[] = [];
    e.steps.forEach((n: any, index: number) => {
      tmp.push({
        step: { value: n.id, label: n.name },
        // Create new date for each step, incrementing by 1 every step
        date: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * (index + 1)),
      });
    });
    setTemplate(e);
    setSteps(tmp);
  };

  // Change date of step
  const changeDateHandler = (i: number, newDate: Date) => {
    const newSteps = [...steps];
    newSteps[i].date = newDate;
    setSteps(newSteps);
  };

  // Change value (name) of step
  const changeStepHandler = (i: number, newValue: Options) => {
    const newSteps = [...steps];
    newSteps[i].step = newValue;
    setSteps(newSteps);
  };

  // Remove step
  const removeStepHandler = (i: number) => {
    const newSteps = [...steps];
    newSteps.splice(i, 1);
    setSteps(newSteps);
  };

  // Add new step below
  const addStepHandler = () => {
    const newSteps = [...steps];
    const item = { step: { value: '', label: '' }, date: new Date() };
    newSteps.push(item);
    setSteps(newSteps);
  };

  // Drag steps list
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const orderedSteps: any = reorder(steps, result.source.index, result.destination.index);
    setSteps(orderedSteps);
  };

  return (
    <div className={css.editCreativityWrapper}>
      <Topbar title={campaign.name}></Topbar>

      <div className={css.editCreativityTitle}>
        <h3>Modifica della creatività</h3>
      </div>

      <div className={css.creativityData}>
        <div className={css.creativityDataLeft}>
          <div>
            <h4>Informazioni della creatività</h4>
            <div className={css.formContent}>
              <div className={css.input}>
                <Controller
                  name='name'
                  as={Input}
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Il nome è obbligatorio' }}
                  label='Nome nuova creatività <span>*</span>'
                />
                <span className={css.error}>{errors.name && errors.name.message}</span>
              </div>
              <div className={css.input}>
                <Controller
                  name='type'
                  as={Select}
                  control={control}
                  defaultValue=''
                  options={[
                    { value: 'video', label: 'Video' },
                    { value: 'banner', label: 'Banner' },
                  ]}
                  label='Tipologia <span>*</span>'
                />
                <span className={css.error}>{errors.type && errors.type.message}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={css.creativityDataRight}>
          <div>
            <div className={css.titleBar}>
              <h4>Fasi della creatività "{creativity.name}"</h4>
              <div className={css.titleBarIcons}>
                <span onClick={() => addStepHandler()}>
                  <Icon icon='add-circle' gray />
                </span>
                <div style={{ width: 200, marginLeft: 12, paddingRight: 20 }}>
                  <Select
                    name='template'
                    options={templateOptions}
                    value={template}
                    onChange={(values: Options) => selectTemplateHandler(values)}
                  />
                </div>
              </div>
            </div>

            <div className={css.errorsWrapper}>{stepErrors && <Alert message={stepErrors} />}</div>

            <div className={css.subTitleBar}>
              <span className={css.titlePhaseName}>Fase</span>
              <span className={css.inputDate}>Data chiusura prevista</span>
              <span className={css.titleDays}>Giorni</span>
            </div>

            <div className={css.content}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='list'>
                  {(provided: any) => (
                    <ul
                      className={css.stepsList}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {steps.map((step: any, index) => (
                        <Draggable draggableId={`step${index}`} index={index} key={index}>
                          {provided => (
                            <li
                              className={css.stepsItem}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <span {...provided.dragHandleProps}>
                                <Icon icon='drag-drop' gray />
                              </span>
                              <div className={css.phaseName}>
                                <Select
                                  name={`step${index}`}
                                  options={stepsOptions}
                                  onChange={(newValue: Options) =>
                                    changeStepHandler(index, newValue)
                                  }
                                  value={step.step}
                                />
                              </div>
                              <div className={css.inputDate}>
                                <InputDate
                                  name={`date${index}`}
                                  onChange={(newDate: Date) => changeDateHandler(index, newDate)}
                                  dateFormat='dd/MM/yyyy'
                                  minDate={new Date()}
                                  selected={step.date}
                                />
                              </div>
                              <div className={css.phaseDays}>
                                <p>
                                  {moment(step.date)
                                    .startOf('day')
                                    .diff(
                                      moment(
                                        index - 1 > -1 ? steps[index - 1].date : new Date(),
                                      ).startOf('day'),
                                      'days',
                                    )}{' '}
                                  giorni
                                </p>
                              </div>
                              <div className={css.options}>
                                <span onClick={() => removeStepHandler(index)}>
                                  <Icon icon='trash' gray />
                                </span>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>

      <div className={css.footer}>
        <div className={css.editDelete}>
          {/* <Icon icon='trash' gray />
          <span>Elimina</span> */}
        </div>
        <span className={css.editBtn}>
          <Button
            text='Annulla'
            styleTransparent
            style={{ marginRight: 12 }}
            onClick={() => history.goBack()}
          />
          <Button
            text='Salva'
            onClick={handleSubmit(submitFormHandler)}
            inLoad={editStatus.loading}
          />
        </span>
      </div>
    </div>
  );
};

export default EditCreativity;
