import React, { useState } from 'react';
import { useEffect } from 'react';
import Parse from 'react-html-parser';

interface MessageProps {
  message: string;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const [formattedMessage, setFormattedMessage] = useState('');

  useEffect(() => {
    if (message) {
      const findAll = message.match(/@(.*?)\)/g);
      let finalMessage = message;

      if (findAll) {
        findAll.forEach((element: string, index: number) => {
          const find = element.match(/\[(.*?)\]/);

          if (find) {
            if (index === 0) {
              finalMessage = message.replace(element, `<span>@${find[1]}</span>`);
            } else {
              finalMessage = finalMessage.replace(element, `<span>@${find[1]}</span>`);
            }
          }
        });
      }
      setFormattedMessage(finalMessage);
    }
  }, [message]);

  return <>{Parse(formattedMessage)}</>;
};

export default Message;
