import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const useCheckAuth = () => {
  const localStorageToken = localStorage.getItem('token');
  const token = useSelector((state: RootState) => state.user.token);

  if (localStorageToken || token) {
    return true;
  } else {
    return false;
  }
};

export default useCheckAuth;
