import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const userStore = createSlice({
  name: 'user',
  initialState: {
    token: null,
    edit: {
      error: null,
      loading: false,
      completed: false,
    },
    add: {
      error: null,
      loading: false,
      completed: { status: false, user: null },
    },
    user: {
      id: null,
      agency_id: null,
      name: '',
      designation: '',
      surname: '',
      email: '',
      type: '',
    },
  } as userStoreTypes,
  reducers: {
    // Get user info
    getUserSuccess(state, action: PayloadAction<getUserTypes>) {
      if (action.payload.token) {
        state.token = action.payload.token;
      }
      state.user = action.payload.user;
    },

    // Edit user
    postEditUserLoading(state) {
      state.edit.loading = true;
      state.edit.error = null;
    },
    postEditUserSuccess(state, action: PayloadAction<userTypes>) {
      state.user = action.payload;
      state.edit.loading = false;
      state.edit.completed = true;
    },
    postEditUserError(state, action: PayloadAction<string>) {
      state.edit.error = action.payload;
      state.edit.loading = false;
    },
    clearEditUser(state) {
      state.edit.completed = false;
    },

    // Add user
    postAddUserLoading(state) {
      state.add.loading = true;
      state.add.error = null;
    },
    postAddUserSuccess(state, action: PayloadAction<any>) {
      state.add.loading = false;
      state.add.completed.status = true;
      state.add.completed.user = action.payload;
    },
    postAddUserError(state, action: PayloadAction<string>) {
      state.add.error = action.payload;
      state.add.loading = false;
    },
    clearAddUserCompleted(state) {
      state.add.completed.status = false;
    },

    postLogout(state) {
      localStorage.removeItem('token');
      state.token = null;
    },
    setTokenFromLocalStorage(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const {
  getUserSuccess,
  postLogout,
  setTokenFromLocalStorage,
  postEditUserLoading,
  postEditUserSuccess,
  postEditUserError,
  clearEditUser,
  postAddUserLoading,
  postAddUserSuccess,
  postAddUserError,
  clearAddUserCompleted,
} = userStore.actions;
