import React from 'react';
import Icon from '../UI/Icon/Icon';
import css from './NotificationsBadge.module.scss';

interface NotificationsBadgeProps {
  notifications: any;
}

const NotificationsBadge: React.FC<NotificationsBadgeProps> = ({ notifications }) => {
  return (
    <>
      {notifications.filter((n: any) => n.read === 0).length > 0 ? (
        <div className={css.notificationsBadge}>
          <img src={require('../../assets/images/megaphone.png').default} alt='' />
          <span>{notifications.filter((n: any) => n.read === 0).length}</span>
        </div>
      ) : null}
    </>
  );
};

export default NotificationsBadge;
