import React from 'react';
import css from './Alert.module.scss';
import Icon from '../Icon/Icon';

interface AlertProps {
  message: string;
}

const Alert: React.FC<AlertProps> = ({ message }) => {
  return (
    <div className={css.alert}>
      <Icon icon='alert-triangle' danger />
      <span>{message}</span>
    </div>
  );
};

export default Alert;
