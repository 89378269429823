import React from 'react';
import css from './Input.module.scss';
import cx from 'classnames';
import Icon from './../Icon/Icon';
import parse from 'html-react-parser';

interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  error?: number;
  icon?: string;
}

const Input = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <label className={css.labelWrapper}>
      {props.label && parse(props.label)}
      {props.icon && (
        <Icon gray noflex pointer icon={props.icon} isIcon={props.icon ? true : false} />
      )}
      <input className={cx(css.input, { [css.error]: props.error })} ref={ref} {...props} />
    </label>
  );
});

export default Input;
