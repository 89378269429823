import React from 'react';
import css from './SignIn.module.scss';
import { RootState } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { postAuth } from '../../store/auth/api';
import { Link } from 'react-router-dom';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import Parse from 'html-react-parser';

const SignIn: React.FC = () => {
  const dispatch = useDispatch();

  const signin = useSelector((state: RootState) => state.auth.signin);
  const { register, control, errors, handleSubmit } = useForm<SignInForm>();

  const onSubmit = (data: SignInForm) => {
    dispatch(postAuth(data));
  };

  return (
    <div className={css.loginWrapper}>
      <div className={css.hero}>
        <div className={css.heroContent}>
          <div className={css.img}>
            <img src='https://picsum.photos/512/372' alt='immagine' />
          </div>
          <div className={css.welcome}>
            <h3>Benvenuto in Rio</h3>
            <p>Un unico spazio per la gestione di tutte le campagne digital.</p>
          </div>
          {/* <div className={css.sliderDots}>
          <ul>
            <li className={css.active}></li>
            <li></li>
            <li></li>
          </ul>
        </div> */}
        </div>
      </div>
      <form className={`${css.formContainer} ${css.prova}`} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.formContent}>
          <h2>Accedi a Rio</h2>
          <p>Bentornato! Esegui il login per accedere al tuo account</p>
          <div className={css.formGroup}>
            <Controller
              name='email'
              as={Input}
              placeholder='name@domain.com'
              control={control}
              defaultValue=''
              rules={{
                required: "L'email è obbligatoria",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Inserisci un indirizzo email corretto',
                },
              }}
              label='Email <span>*</span>'
              error={errors.email ? 1 : 0}
            />
            <span className={css.err}>{errors.email && errors.email.message}</span>
          </div>
          <div className={css.formGroup}>
            <Controller
              name='password'
              type='password'
              as={Input}
              control={control}
              defaultValue=''
              rules={{ required: 'La password è obbligatoria' }}
              label='Password <span>*</span>'
              placeholder='Password'
              error={errors.password ? 1 : 0}
            />
            <span className={css.err}>{errors.password && errors.password.message}</span>
          </div>
          <div className={css.cta}>
            <div className={css.formGroup}>
              <Checkbox type='checkbox' name='remember' label='Ricordami' ref={register} />
            </div>
            <div className={css.formGroup}>
              <Link to='lostpassword'>
                <span className={css.link}>Hai dimenticato la password?</span>
              </Link>
            </div>
          </div>

          {signin.error && <span className={css.err}>{signin.error}</span>}
          <Button
            style={{ textAlign: 'center', display: 'block' }}
            fullwidth
            text='Log in'
            inLoad={signin.loading}
          />
        </div>
      </form>
    </div>
  );
};

export default SignIn;
