import React, { useEffect, useState } from 'react';
import css from './Editor.module.scss';
import { Editor, EditorState, RichUtils, CompositeDecorator } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import InputFile from '../InputFile/InputFile';
import Input from '../Input/Input';

interface EditorProps {
  changed: any;
  updateAttachments?: any;
  value?: string;
  oldAttachments?: any;
  oldImages?: any;
}

const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Link = (props: any) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ textDecoration: 'underline', color: 'blue' }}>
      {props.children}
    </a>
  );
};

const RichEditor: React.FC<EditorProps> = ({
  changed,
  value,
  updateAttachments,
  oldAttachments,
  oldImages,
}) => {
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const [attachments, setAttachments] = useState<FileTypes[]>([]);
  const [images, setImages] = useState<FileTypes[]>([]);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator));
  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);
  const [link, setLink] = useState('');

  const openModalLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setLink(url);
      setIsModalLinkOpen(true);
    }
  };

  const confirmLink = () => {
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: link });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let nextEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      nextEditorState.getSelection(),
      entityKey,
    );

    setEditorState(nextEditorState);
  };

  const removeAttachmentsHandler = (i: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(i, 1);
    setAttachments(newAttachments);
  };

  const removeImagesHandler = (i: number) => {
    const newImages = [...images];
    newImages.splice(i, 1);
    setImages(newImages);
  };

  useEffect(() => {
    changed(stateToHTML(editorState.getCurrentContent()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState.getCurrentContent()]);

  useEffect(() => {
    if (value) {
      let contentState = stateFromHTML(value);
      const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      setEditorState(newEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    updateAttachments({
      attachments,
      images,
    });
  }, [attachments, images, updateAttachments]);

  useEffect(() => {
    if (oldAttachments) {
      setAttachments(oldAttachments);
    }
    if (oldImages) {
      setImages(oldImages);
    }
  }, [oldAttachments, oldImages]);

  return (
    <div className={css.editorWrapper}>
      <div className={css.editorHeader}>
        <div onClick={() => openModalLink()} className={css.action}>
          <Icon icon='link-horizontal' gray />
        </div>
        <div className={css.fileButtons}>
          <InputFile
            name='images'
            iconInput='image'
            className={css.action}
            changed={(selectedFiles: FileTypes[]) => setImages([...images, ...selectedFiles])}
            accept='image/*'
          />
          <InputFile
            name='attachments'
            iconInput='papaerclip'
            className={css.action}
            changed={(selectedFiles: FileTypes[]) =>
              setAttachments([...attachments, ...selectedFiles])
            }
          />
        </div>
      </div>

      <Editor editorState={editorState} onChange={setEditorState} />

      {(images.length > 0 || attachments.length > 0) && (
        <div className={css.editorFooter}>
          {images.map((image: any, i: number) => (
            <div className={css.fileBox} key={i}>
              <div>
                <Icon icon='image' gray />
                <span>{image.name.length > 25 ? image.name.slice(0, 25) + ' ..' : image.name}</span>
              </div>
              <div onClick={() => removeImagesHandler(i)}>
                <Icon icon='close' gray />
              </div>
            </div>
          ))}
          {attachments.map((att: any, i: number) => (
            <div className={css.fileBox} key={i}>
              <div>
                <Icon icon='image' gray />
                <span>{att.name.length > 25 ? att.name.slice(0, 25) + ' ..' : att.name}</span>
              </div>
              <div onClick={() => removeAttachmentsHandler(i)}>
                <Icon icon='close' gray />
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={isModalLinkOpen}
        onClose={() => setIsModalLinkOpen(false)}
        onSubmit={() => {
          confirmLink();
          setIsModalLinkOpen(false);
        }}
        submitButton={{ text: 'Aggiungi link' }}
        title='Aggiungi o modifica link'
      >
        <div className={css.modalBodyContent}>
          <Input name='' value={link} onChange={(e: any) => setLink(e.currentTarget.value)}></Input>
        </div>
        {/* <input type='text' value={link} onChange={(e: any) => setLink(e.currentTarget.value)} /> */}
      </Modal>
    </div>
  );
};

export default RichEditor;
