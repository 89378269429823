import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const approvalStore = createSlice({
  name: 'approval',
  initialState: {
    add: {
      loading: false,
      completed: false,
      error: null as null | string,
    },
    edit: {
      loading: false as boolean | number | string,
      error: null as null | string,
      completed: false as boolean | number | string,
    },
    delete: {
      loading: false as boolean | number | string,
      error: null as null | string,
    },
  },
  reducers: {
    // Add approval
    sendForApprovalLoading(state) {
      state.add.loading = true;
      state.add.error = null;
    },
    sendForApprovalSuccess(state) {
      state.add.loading = false;
      state.add.completed = true;
    },
    sendForApprovalError(state, action: PayloadAction<string>) {
      state.add.loading = false;
      state.add.error = action.payload;
    },
    clearSendForApprovalSuccess(state) {
      state.add.completed = false;
    },

    // Edit approval
    changeApprovalStatusSuccess(state, action?: PayloadAction<number | string>) {
      state.edit.loading = false;
      if (action?.payload) {
        state.edit.completed = action.payload;
      }
    },
    changeApprovalStatusLoading(state, action: PayloadAction<number | string>) {
      state.edit.loading = action.payload;
      state.edit.error = null;
    },
    changeApprovalStatusError(state, action: PayloadAction<string>) {
      state.edit.error = action.payload;
      state.edit.loading = false;
    },
    clearApprovalStatus(state) {
      state.edit.completed = false;
    },

    // Delete approval
    deleteApprovalRequestLoading(state) {
      state.delete.loading = true;
      state.delete.error = '';
    },
    deleteApprovalRequestError(state, action: PayloadAction<string>) {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
    deleteApprovalRequestSuccess(state) {
      state.delete.loading = false;
      state.delete.error = '';
    },
  },
});

export const {
  sendForApprovalSuccess,
  sendForApprovalLoading,
  sendForApprovalError,
  clearSendForApprovalSuccess,
  changeApprovalStatusSuccess,
  changeApprovalStatusLoading,
  changeApprovalStatusError,
  clearApprovalStatus,
  deleteApprovalRequestLoading,
  deleteApprovalRequestError,
  deleteApprovalRequestSuccess,
} = approvalStore.actions;
