import React from 'react';
import css from './LostPassword.module.scss';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postLostPassword } from '../../store/auth/api';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import { RootState } from '../../store/store';
import { Link } from 'react-router-dom';
import Parse from 'html-react-parser';

const LostPassword: React.FC = () => {
  const dispatch = useDispatch();

  const lostpassword = useSelector((state: RootState) => state.auth.lostpassword);
  const { control, errors, handleSubmit } = useForm<LostPasswordForm>();

  const onSubmit = (data: LostPasswordForm) => {
    dispatch(postLostPassword(data));
  };

  return (
    <div className={css.lostPasswordWrapper}>
      <div className={css.hero}>
        <div className={css.heroContent}>
          <div className={css.img}>
            <img src='https://picsum.photos/512/372' alt='immagine' />
          </div>
          <div className={css.welcome}>
            <h3>Benvenuto in Rio</h3>
            <p>Un unico spazio per la gestione di tutte le campagne digital.</p>
          </div>
        </div>
      </div>
      <form className={css.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.formContent}>
          <h2>Hai dimenticato la password?</h2>
          <p>Inserisci il tuo indirizzo mail per permetterci di identificare il tuo account</p>
          <div className={css.formGroup}>
            <Controller
              name='email'
              as={Input}
              control={control}
              placeholder='name@domain.com'
              defaultValue=''
              rules={{
                required: "L'email è obbligatoria",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Inserisci un indirizzo email corretto',
                },
              }}
              label='Email <span>*</span>'
              error={errors.email ? 1 : 0}
            />
            <span className={css.err}>{errors.email && errors.email.message}</span>
          </div>
          <div className={css.btn}>
            <Button
              style={{ textAlign: 'center', display: 'block' }}
              fullwidth
              text='Recupera password'
            />
          </div>

          {lostpassword.loading && 'Caricamento'}
          {lostpassword.error && lostpassword.error}
          <div className={css.formGroup}>
            <div className={css.link}>
              <Link to='signin'>
                <span className={css.link}>Torna al Login</span>{' '}
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LostPassword;
