import React, { useEffect } from 'react';
import css from './SignUp.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Select from '../../components/UI/Select/Select';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import { getVerifyToken, postCompleteRegistration } from '../../store/auth/api';
import { clearCompleteRegistration } from '../../store/auth/slice';

type ParamTypes = { token: string };

const SignUp: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = useParams<ParamTypes>();

  const signup = useSelector((state: RootState) => state.auth.signup);
  const tempUser = useSelector((state: RootState) => state.auth.signup.tempUser);
  const { register, control, errors, setValue, handleSubmit } = useForm<RegistrationForm>();

  const roleOptions = [
    { value: 'dev', label: 'Developer' },
    { value: 'pm', label: 'Project manager' },
    { value: 'other', label: 'Other' },
  ];

  const onSubmit = (data: RegistrationForm) => {
    data.type = data.type.value;
    dispatch(postCompleteRegistration({ ...data, token }));
  };

  useEffect(() => {
    if (token) {
      dispatch(getVerifyToken(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (tempUser.user.email) {
      setValue('email', tempUser.user.email);
      setValue('name', tempUser.user.name);
      setValue('surname', tempUser.user.surname);
      setValue(
        'type',
        roleOptions.find(opt => opt.value === tempUser.user.type),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempUser.user, setValue]);

  useEffect(() => {
    if (tempUser.error) {
      history.push('/signin');
    }
  }, [history, tempUser.error]);

  useEffect(() => {
    if (tempUser.completed) {
      history.push('/signin');
      dispatch(clearCompleteRegistration());
    }
  }, [dispatch, history, tempUser.completed]);

  return (
    <div className={css.signUpWrapper}>
      <div className={css.hero}>
        <div className={css.heroContent}>
          <div className={css.img}>
            <img src='https://picsum.photos/512/372' alt='immagine' />
          </div>
          <div className={css.welcome}>
            <h3>Benvenuto in Rio</h3>
            <p>Un unico spazio per la gestione di tutte le campagne digital.</p>
          </div>
        </div>
      </div>
      <form className={css.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.formContent}>
          <h2>Registrati a Rio!</h2>
          <p>Inserisci i dati per completare la registrazione</p>
          <div className={css.formGroup}>
            <Controller
              name='email'
              as={Input}
              control={control}
              placeholder='name@domain.com'
              defaultValue=''
              disabled
              rules={{ required: "L'email è obbligatoria" }}
              label='Email <span>*</span>'
              error={errors.email ? 1 : 0}
            />
            <span className={css.err}>{errors.email && errors.email.message}</span>
          </div>
          <div className={css.formGroup}>
            <Controller
              name='name'
              as={Input}
              control={control}
              defaultValue=''
              rules={{ required: 'Il nome è obbligatorio' }}
              label='Nome <span>*</span>'
              error={errors.name ? 1 : 0}
            />
            <span className={css.err}>{errors.name && errors.name.message}</span>
          </div>
          <div className={css.formGroup}>
            <Controller
              name='surname'
              as={Input}
              control={control}
              defaultValue=''
              rules={{ required: 'Il cognome è obbligatorio' }}
              label='Cognome <span>*</span>'
              error={errors.surname ? 1 : 0}
            />
            <span className={css.err}>{errors.surname && errors.surname.message}</span>
          </div>
          <div className={css.formGroup}>
            <Controller
              name='type'
              as={Select}
              control={control}
              defaultValue=''
              placeholder='Seleziona il ruolo'
              rules={{ required: 'Il ruolo è obbligatorio' }}
              options={roleOptions}
              label='Ruolo <span>*</span>'
              // error={errors.role ? 1 : 0}
            />
            <span className={css.err}>{errors.type && errors.type.message}</span>
          </div>
          <div className={css.formGroup}>
            <Controller
              name='password'
              type='password'
              as={Input}
              control={control}
              defaultValue=''
              rules={{ required: 'La password è obbligatoria' }}
              label='Password <span>*</span>'
              error={errors.password ? 1 : 0}
            />
            <span className={css.err}>{errors.password && errors.password.message}</span>
          </div>
          <div className={css.formGroup}>
            <Checkbox
              type='checkbox'
              name='terms'
              label='Accetto i termini e le condizioni generali <span>*</span>'
              ref={register({ required: 'I termini sono obbligatori' })}
              // error={errors.terms ? 1 : 0}
            />
            <span className={css.err}>{errors.terms && errors.terms.message}</span>
          </div>
          <Button
            style={{ textAlign: 'center', display: 'block' }}
            fullwidth
            text='Registrati'
            inLoad={signup.loading}
          />

          {signup.error && signup.error}
        </div>
      </form>
    </div>
  );
};

export default SignUp;
