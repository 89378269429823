import React, { useEffect, useState } from 'react';
import css from './EditFormatEditor.module.scss';
import { useDispatch } from 'react-redux';
import {
  deleteFormat,
  createNewVersion,
  updateFormatVersion,
  updateFormatVisibility,
} from '../../../../store/formats/api';
import Button from '../../../../components/UI/Button/Button';
import Editor from '../../../../components/UI/Editor/Editor';
import Icon from '../../../../components/UI/Icon/Icon';

interface EditFormatEditorProps {
  currentVersion: any;
  format: any;
  folder: any;
  cancel: any;
}

const EditFormatEditor: React.FC<EditFormatEditorProps> = ({
  currentVersion,
  cancel,
  format,
  folder,
}) => {
  const dispatch = useDispatch();

  const [editorContent, setEditorContent] = useState<string>('');
  const [attachments, setAttachments] = useState<FileTypes[]>([]);
  const [images, setImages] = useState<FileTypes[]>([]);

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
  };

  const handleAttachmentsChange = (files: any) => {
    setAttachments(files.attachments);
    setImages(files.images);
  };

  useEffect(() => {
    setEditorContent(currentVersion.content.replaceAll('\\', ''));
  }, [currentVersion]);

  const deleteHandler = () => {
    dispatch(deleteFormat(format.id, folder.id));
  };

  const clearContentAndTitle = (editorContent: string) => {
    const content = editorContent.replaceAll('&nbsp;', ' ');
    let title = content.split('<a')[0];
    title = title.replaceAll(/<[^>]*>?/g, '');

    return [title, content];
  };

  const updateFormatHandler = () => {
    const newFormat = { ...currentVersion };
    const [clearTitle, clearContent] = clearContentAndTitle(editorContent);
    newFormat.content = clearContent;
    newFormat.title = clearTitle;
    newFormat.status = format.status;
    newFormat.attachments = attachments;
    newFormat.images = images;

    dispatch(updateFormatVersion(newFormat, currentVersion.id));
  };

  const newVersionHandler = () => {
    const [clearTitle, clearContent] = clearContentAndTitle(editorContent);
    const newFormat = {
      id: format.id,
      content: clearContent,
      title: clearTitle,
      images,
      attachments,
    };
    dispatch(createNewVersion(newFormat));
  };

  return (
    <div className={css.wrapper}>
      <Editor
        changed={handleEditorChange}
        value={currentVersion.content.replaceAll('\\', '')}
        updateAttachments={handleAttachmentsChange}
        oldAttachments={currentVersion.attachments}
        oldImages={currentVersion.images}
      />

      <div className={css.editorActions}>
        <div className={css.editorActionsDelete} onClick={deleteHandler}>
          <Icon icon='trash' gray />
        </div>
        <div className={css.editorActionsRight}>
          <Button text='Annulla' styleTransparent onClick={cancel} style={{ marginRight: 8 }} />
          <Button
            text='Crea nuova versione'
            styleTransparent
            onClick={newVersionHandler}
            style={{ marginRight: 8 }}
          />
          <Button text='Salva' onClick={updateFormatHandler} />
        </div>
      </div>
    </div>
  );
};

export default EditFormatEditor;
