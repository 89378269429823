import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { clearEditUser, postLogout } from '../../store/user/slice';
import css from './Profile.module.scss';
import Icon from '../../components/UI/Icon/Icon';
import { RootState } from '../../store/store';
import Button from '../../components/UI/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/UI/Input/Input';
import { postEditUser } from '../../store/user/api';
import Avatar from '../../components/Avatar/Avatar';
import Parse from 'html-react-parser';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const editStatus = useSelector((state: RootState) => state.user.edit);

  const [editProfile, setEditProfile] = useState<boolean>(false);
  const { control, errors, handleSubmit } = useForm<ProfileFormTypes>();

  const onSubmit = (data: ProfileFormTypes) => {
    dispatch(postEditUser(data));
  };

  useEffect(() => {
    if (editStatus.completed) {
      setEditProfile(false);
      dispatch(clearEditUser());
    }
  }, [dispatch, editStatus.completed]);

  return (
    <div className={css.container}>
      <div className={css.accountMenu}>
        <div className={css.accountMenuTop}>
          <h3>Il tuo account</h3>
        </div>
        <div>
          <ul>
            <li>
              <NavLink to='/profile' onClick={() => setEditProfile(false)}>
                <Icon icon='profile' gray></Icon>
                <span>Il tuo profilo</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/signIn' onClick={() => dispatch(postLogout())}>
                <Icon icon='log-out' gray></Icon>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className={css.accountInfo}>
        <div className={css.accountInfoTop}>
          <div></div>
          <h3>{editProfile ? 'Modifica il tuo profilo' : 'Il tuo profilo'}</h3>
          <div className='logoClient'>
            <img src={require('../../assets/images/logo-eni.png').default} alt='' />
          </div>
        </div>
        <div className={css.accountInfoMain}>
          <div className={css.accountInfoMainWrapper}>
            <div className={css.accountInfoMainEdit}>
              <h4>Informazioni personali</h4>
              {/* <Link to='/editprofile'> */}
              {!editProfile && (
                <div className={css.editIcon}>
                  <Icon icon='edit' gray onClick={() => setEditProfile(!editProfile)} />
                </div>
              )}
              {/* </Link> */}
            </div>
            <div className={css.accountInfoMainBottom}>
              <div className={css.accountInfoAvatar}>
                <Avatar user={user} size={130} />
                {editProfile && (
                  <div className={css.accountInfoEdit}>
                    <Icon icon='edit' gray />
                  </div>
                )}
              </div>
              <div className={css.accountData}>
                {!editProfile ? (
                  <div>
                    <ul className={css.userName}>
                      <li>
                        <div className={css.label}>Nome</div>
                        <div className={css.data}>{user.name}</div>
                      </li>
                      <li>
                        <div className={css.label}>Cognome</div>
                        <div className={css.data}>{user.surname}</div>
                      </li>
                    </ul>
                    <ul className={css.userData}>
                      <li>
                        <div className={css.label}>Ruolo</div>
                        <div className={css.data}>{user.type}</div>
                      </li>
                      <li>
                        <div className={css.label}>Email</div>
                        <div className={css.data}>{user.email}</div>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <form>
                    <div className={css.userNameInputWrapper}>
                      <div className={css.userNameInput}>
                        <div>
                          <Controller
                            name='name'
                            control={control}
                            defaultValue={user.name}
                            as={Input}
                            label='Nome <span>*</span>'
                            rules={{ required: 'Il nome è obbligatorio' }}
                          />
                        </div>
                        <span>{errors.name && errors.name.message}</span>
                        <div>
                          <Controller
                            name='surname'
                            control={control}
                            defaultValue={user.surname}
                            as={Input}
                            label='Cognome <span>*</span>'
                          />
                        </div>
                      </div>
                    </div>
                    <div className={css.userDataInput}>
                      <div>
                        <Controller
                          name='type'
                          control={control}
                          defaultValue={user.type}
                          as={Input}
                          label='Ruolo <span>*</span>'
                          rules={{ required: 'Il ruolo è obbligatorio' }}
                        />
                        {/* <span>{errors.type && errors.type.message}</span> */}
                      </div>

                      <div>
                        <Controller
                          name='email'
                          control={control}
                          defaultValue={user.email}
                          as={Input}
                          label='Email <span>*</span>'
                          rules={{ required: "L'email è obbligatoria" }}
                        />
                        <span>{errors.email && errors.email.message}</span>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        {editProfile && (
          <div className={css.accountInfoBottom}>
            <div className={css.accountInfoBottomDelete}>
              <Icon icon='trash' gray />
              <span>Elimina</span>
            </div>
            <div className={css.accountInfoBottomActions}>
              <Button
                text='Annulla'
                styleTransparent
                style={{ marginRight: 8 }}
                onClick={() => setEditProfile(!editProfile)}
              />
              <Button text='Salva' inLoad={editStatus.loading} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
