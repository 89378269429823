import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const chatStore = createSlice({
  name: 'chat',
  initialState: {
    get: {
      loading: false,
      messages: [] as any[],
      formatMessages: [] as any[],
    },
    add: {
      error: null as null | string,
    },
  },
  reducers: {
    // Get all messages
    getFolderMessagesSuccess(state, action: PayloadAction<any[]>) {
      state.get.messages = action.payload;
      state.get.loading = false;
    },
    getFolderMessagesLoading(state) {
      state.get.loading = true;
    },
    getFormatMessagesSuccess(state, action: PayloadAction<any>) {
      const updateState = state.get.formatMessages.find((fm: any) => fm.id === action.payload.id);
      if (updateState) {
        updateState.messages = action.payload.messages;
      } else {
        state.get.formatMessages = [...state.get.formatMessages, action.payload];
      }
    },

    // Get new message
    getNewFolderMessage(state, action: PayloadAction<any>) {
      state.get.messages = [...state.get.messages, action.payload];
    },
    getNewFormatMessage(state, action: PayloadAction<any>) {
      const updateState = state.get.formatMessages.find(
        (fm: any) => fm.id === action.payload.formatId,
      );
      if (updateState) {
        updateState.messages = [...updateState.messages, action.payload.data];
      }
    },

    // Post new message
    postNewFolderMessageSuccess(state) {
      state.add.error = null;
    },
    postNewFolderMessageError(state, action: PayloadAction<string>) {
      state.add.error = action.payload;
    },
  },
});

export const {
  getFolderMessagesSuccess,
  getFolderMessagesLoading,
  getNewFolderMessage,
  getNewFormatMessage,
  postNewFolderMessageSuccess,
  postNewFolderMessageError,
  getFormatMessagesSuccess,
} = chatStore.actions;
