import React from 'react';
import css from './Select.module.scss';
import cx from 'classnames';
import ReactSelect from 'react-select';
import parse from 'html-react-parser';

interface SelectProps {
  disabled?: boolean;
  placeholder?: string;
  options: Options[];
  label?: string;
  value?: Options;
  name?: string;
  style?: any;
  onChange?: any;
  error?: number;
}

const Select = React.forwardRef((props: SelectProps, ref: React.Ref<ReactSelect>) => {
  return (
    <label className={css.labelWrapper}>
      {props.label && parse(props.label)}
      <ReactSelect
        classNamePrefix={'select'}
        className={cx(css.wrapper, { [css.error]: props.error })}
        ref={ref}
        {...props}
      />
    </label>
  );
});

export default Select;
