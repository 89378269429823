import React, { useEffect, useState } from 'react';
import parse from 'react-html-parser';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EditFormatEditor from '../EditFormatEditor/EditFormatEditor';
import css from './Format.module.scss';
import cx from 'classnames';
import { useIsInternalMember, useIsOwner } from '../../../../hooks/useRoles';
import { RootState } from '../../../../store/store';
import Icon from '../../../../components/UI/Icon/Icon';
import DropDownMenu from '../../../../components/UI/DropDownMenu/DropDownMenu';
import Modal from '../../../../components/UI/Modal/Modal';
import { updateFormatVisibility } from '../../../../store/formats/api';
import ApprovalManager from './ApprovalManager/ApprovalManager';
import { hasReadNotifications } from '../../../../store/notifications/api';

interface FormatProps {
  format: any;
  folder: any;
  selected: boolean;
  changeSelected: () => void;
  changeSelectedChat: () => void;
  sendForApproval: () => void;
}

interface PreviewTypes {
  type: string;
  link: string;
  ext: string;
}

const Format: React.FC<FormatProps> = ({
  format,
  folder,
  selected,
  changeSelected,
  changeSelectedChat,
  sendForApproval,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isIntMember = useIsInternalMember();
  const isIntOwner = useIsOwner();

  const creativity = useSelector((state: RootState) => state.creativity.creativity);
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const [currentVersion, setCurrentVersion] = useState<any>({
    id: 0,
    content: '',
    images: [],
    attachments: [],
    approvals: [],
    version: 0,
  });

  const [confirmChangeVisibilityModal, setConfirmChangeVisibilityModal] = useState(false);
  const [hasNotify, setHasNotify] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [preview, setPreview] = useState<PreviewTypes>({
    type: '',
    link: '',
    ext: '',
  });

  const changeCurrentVersionHandler = (id: number) => {
    const newVersion = format.versions.find((x: any) => x.id === id);
    setCurrentVersion(newVersion);
  };

  const changeVisibilityHandler = () => {
    setConfirmChangeVisibilityModal(true);
  };

  const confirmChangeVisibilityHandler = () => {
    dispatch(updateFormatVisibility(format.id, !format.visibility));
  };

  // Set current version on component load or format changes
  useEffect(() => {
    const emptyVersion = {
      id: 0,
      content: '',
      images: [],
      attachments: [],
      approvals: [],
      version: 0,
    };
    if (format.versions.length > 0) {
      setCurrentVersion(format.versions.slice(-1)[0]);
    } else {
      setCurrentVersion(emptyVersion);
    }
  }, [format]);

  // Check and set preview (if image or <a> tag in content)
  useEffect(() => {
    if (currentVersion.images.length > 0) {
      setPreview({
        type: 'image',
        link: currentVersion.images[0].path,
        ext: currentVersion.images[0].ext,
      });
    } else if (currentVersion.content.replaceAll('\\', '').includes('href=')) {
      setPreview({
        type: 'iframe',
        link: currentVersion.content
          .replaceAll('\\', '')
          .match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2],
        ext: 'link',
      });
    } else {
      setPreview({ type: '', link: '', ext: '' });
    }
  }, [currentVersion]);

  useEffect(() => {
    const listToRead: any[] = [];
    notifications.forEach((notification: any) => {
      if (
        notification.creativities_format_id === format.id &&
        notification.type !== 'message' &&
        notification.read === 0
      ) {
        listToRead.push(notification.id);
      }
    });
    if (listToRead.length > 0) {
      setHasNotify(true);
      dispatch(hasReadNotifications(listToRead));
    }
  }, [dispatch, format, notifications]);

  return (
    <>
      <div className={css.wrapper}>
        {isEditable ? (
          <EditFormatEditor
            currentVersion={currentVersion}
            cancel={() => setIsEditable(false)}
            format={format}
            folder={folder}
          />
        ) : (
          <div className={css.format}>
            <span
              className={cx(css.formatSelector, { [css.selected]: selected })}
              onClick={changeSelected}
            />

            <div
              className={cx(css.contentWrapper, {
                [css.withNotify]: hasNotify,
                [css.formatSelected]: selected,
              })}
            >
              <Link
                className={css.content}
                to={`/campaign/${campaign.id}/creativity/${creativity.id}/folder/${folder.id}/preview/${format.id}`}
              >
                <div className={css.infoTop}>
                  <h4>{parse(currentVersion.title)}</h4>
                  {isIntMember && (
                    <div
                      className={css.editButton}
                      onClick={e => {
                        e.preventDefault();
                        setIsEditable(true);
                      }}
                    >
                      <Icon icon='edit' gray />
                    </div>
                  )}
                </div>
                <div className={css.infoWrapper}>
                  <ApprovalManager
                    folderId={folder.id}
                    approvals={currentVersion.approvals}
                    format={format}
                    sendForApproval={sendForApproval}
                    showSendForApprovalButton={true}
                  />
                  <div className={css.icons}>
                    {(currentVersion.attachments.length > 0 ||
                      currentVersion.images.length > 0) && (
                      <div className={cx(css.attachments, css.iconItem)}>
                        <DropDownMenu
                          icon='papaerclip_s'
                          label={
                            '+ ' +
                            (currentVersion.attachments.length + currentVersion.images.length)
                          }
                          menuItems={[...currentVersion.attachments, ...currentVersion.images].map(
                            (file: any) => ({
                              label: file.name,
                              onClick: () => {
                                history.push(
                                  `/campaign/${campaign.id}/creativity/${creativity.id}/folder/${folder.id}/preview/${format.id}`,
                                );
                              },
                            }),
                          )}
                          smallMenu
                        />
                      </div>
                    )}

                    <div className={css.iconItem}>
                      {format.versions.length > 1 ? (
                        <DropDownMenu
                          label={'V' + currentVersion.version}
                          menuItems={format.versions.map((v: any) => ({
                            label: `V${v.version}`,
                            onClick: (e: any) => changeCurrentVersionHandler(v.id),
                          }))}
                          smallMenu
                        />
                      ) : (
                        <span>{'V' + currentVersion.version}</span>
                      )}
                    </div>

                    {isIntMember && (
                      <div
                        className={css.iconItem}
                        onClick={e => {
                          e.preventDefault();
                          isIntOwner && changeVisibilityHandler();
                        }}
                      >
                        <Icon icon={format.visibility ? 'details' : 'details-off'} gray />
                      </div>
                    )}

                    <div
                      className={css.iconItem}
                      onClick={e => {
                        e.preventDefault();
                        changeSelectedChat();
                      }}
                    >
                      <Icon icon='chat' gray />
                    </div>
                  </div>
                </div>
              </Link>

              {preview.type ? (
                <Link
                  to={`/campaign/${campaign.id}/creativity/${creativity.id}/folder/${folder.id}/preview/${format.id}`}
                  className={css.previewWrapper}
                >
                  {preview.type === 'image' ? (
                    <div className={css.previewImage}>
                      <img src={preview.link} alt='' />
                    </div>
                  ) : (
                    <>
                      <div className={css.previewIcon}>
                        <Icon primary icon='play' size={20} />
                      </div>
                    </>
                  )}
                </Link>
              ) : (
                <Link
                  to={`/campaign/${campaign.id}/creativity/${creativity.id}/folder/${folder.id}/preview/${format.id}`}
                  className={css.noPreview}
                >
                  <div className={css.dashed}>
                    <Icon icon='upload-cloud' gray />
                  </div>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={confirmChangeVisibilityModal}
        onClose={() => setConfirmChangeVisibilityModal(false)}
        onSubmit={() => {
          confirmChangeVisibilityHandler();
          setConfirmChangeVisibilityModal(false);
        }}
        submitButton={{ text: 'Conferma' }}
        title='Cambio di visibilità'
      >
        <div className={css.modalBodyContent}>
          <p style={{ padding: 25 }}>
            Sei sicuro di voler cambiare la visibilità di questo formato?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Format;
