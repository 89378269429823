import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';

import { getCreativitiesLoading, getCreativitiesSuccess } from './slice';

export const getCreativities =
  (campaignId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(getCreativitiesLoading());
      const res = await customAxios.get('/creativities', {
        params: { campaign_id: campaignId, limit: 50 },
      });
      dispatch(getCreativitiesSuccess(res.data));
    } catch (err) {
      console.log(err);
    }
  };
