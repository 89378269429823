import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import moment from 'moment';
import {
  getCreativityLoading,
  getCreativitySuccess,
  getCreativityError,
  postAddCreativitySuccess,
  postAddCreativityLoading,
  postAddCreativityError,
  putUpdateCreativityLoading,
  putUpdateCreativitySuccess,
  putUpdateCreativityError,
  deleteCreativityLoading,
  deleteCreativitySuccess,
} from './slice';
import { getCampaign } from '../campaign/api';

interface stepsFormattedTypes {
  name: string;
  visibility: number;
  order: number;
  beginDate: string;
  endDate: string;
}

export const getCreativity =
  (creativityId: number, force?: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      const campaign = getState().campaign.campaign;
      const creativities = getState().creativities.creativities;

      const currentCreativity = creativities.find((c: CreativityTypes) => c.id === creativityId);

      if (currentCreativity && !force) {
        dispatch(getCreativitySuccess(currentCreativity));
      } else {
        dispatch(getCreativityLoading());
        const res = await customAxios.get('/creativities', { params: { id: creativityId } });
        dispatch(getCreativitySuccess(res.data));

        if (!campaign.id) {
          dispatch(getCampaign(res.data.campaign_id));
        }
        dispatch(getCreativitySuccess(res.data));
      }
    } catch (err) {
      dispatch(getCreativityError(err.response.data.error));
    }
  };

export const postAddCreativity =
  (data: any): AppThunk =>
  async (dispatch, getState) => {
    const company_id = getState().company.company.id;
    const agency_id = getState().company.company.agency_id;
    const campaign_id = getState().campaign.campaign.id;

    try {
      const stepsFormatted: stepsFormattedTypes[] = [];
      data.data.forEach((step: PhasesForm, index: number) => {
        stepsFormatted.push({
          name: step.step.label,
          visibility: 1,
          order: index,
          beginDate:
            index === 0
              ? moment(new Date()).format('YYYY-MM-DD')
              : moment(data.data[index - 1].date).format('YYYY-MM-DD'),
          endDate: moment(step.date).format('YYYY-MM-DD'),
        });
      });

      dispatch(postAddCreativityLoading());
      await customAxios.post('/creativities', {
        agency_id,
        company_id,
        campaign_id,
        name: data.name,
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
        steps: stepsFormatted,
        creativity_type: 'Banner',
      });
      dispatch(postAddCreativitySuccess());
    } catch (err) {
      dispatch(postAddCreativityError(err.response.data.error));
    }
  };

export const putUpdateCreativity =
  (data: any): AppThunk =>
  async dispatch => {
    const stepsFormatted: stepsFormattedTypes[] = [];
    data.steps.forEach((step: PhasesForm, index: number) => {
      stepsFormatted.push({
        name: step.step.label,
        visibility: 1,
        order: index,
        beginDate:
          index === 0
            ? moment(new Date()).format('YYYY-MM-DD')
            : moment(data.steps[index - 1].date).format('YYYY-MM-DD'),
        endDate: moment(step.date).format('YYYY-MM-DD'),
      });
    });

    data.steps = stepsFormatted;

    try {
      dispatch(putUpdateCreativityLoading());
      await customAxios.put(`/creativities/${data.id}`, data);
      dispatch(putUpdateCreativitySuccess());
      dispatch(getCreativity(data.id, true));
    } catch (err) {
      dispatch(putUpdateCreativityError(err.response.data.error));
    }
  };

export const putUpdateStepCreativity =
  (data: any): AppThunk =>
  async (dispatch, getState) => {
    const company_id = getState().company.company.id;
    const agency_id = getState().company.company.agency_id;
    const campaign_id = getState().campaign.campaign.id;

    const dataToSend = {
      ...data,
      company_id,
      agency_id,
      campaign_id,
    };

    try {
      dispatch(putUpdateCreativityLoading());
      await customAxios.put(`/creativities/${data.id}`, dataToSend);
      dispatch(putUpdateCreativitySuccess());
      dispatch(getCreativity(data.id, true));
    } catch (err) {
      dispatch(putUpdateCreativityError(err.response.data.error));
    }
  };

export const deleteCreativity =
  (creativityId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(deleteCreativityLoading());
      await customAxios.delete(`/creativities/${creativityId}`);
      dispatch(deleteCreativitySuccess());
      dispatch(getCreativity(creativityId, true));
    } catch (err) {
      console.log(err);
    }
  };
