import React from 'react';
import cx from 'classnames';
import css from './Icon.module.scss';
import { ReactSVG } from 'react-svg';

interface IconProps {
  icon: string;
  gray?: boolean;
  primary?: boolean;
  red?: boolean;
  green?: boolean;
  orange?: boolean;
  light?: boolean;
  danger?: boolean;
  size?: number;
  noflex?: boolean;
  position?: 'right' | 'left';
  pointer?: boolean;
  isIcon?: boolean;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = props => {
  return (
    <ReactSVG
      src={require(`../../../assets/icons/${props.icon}.svg`).default}
      className={cx(css.icon, {
        [css.gray]: props.gray,
        [css.red]: props.red,
        [css.green]: props.green,
        [css.orange]: props.orange,
        [css.primary]: props.primary,
        [css.light]: props.light,
        [css.danger]: props.danger,
        [css.size_20]: props.size === 20,
        [css.size_22]: props.size === 22,
        [css.isInputIcon]: props.isIcon,
        [css.noflex]: props.noflex,
        [css.pointer]: props.pointer,
      })}
      onClick={props.onClick}
    />
  );
};

export default Icon;
