import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const appStore = createSlice({
  name: 'app',
  initialState: {
    isSidebarOpen: false,
  },
  reducers: {
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { setSidebarOpen } = appStore.actions;
