import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const usersStore = createSlice({
  name: 'users',
  initialState: {
    users: [] as userTypes[],
  },
  reducers: {
    getUsersSuccess(state, action: PayloadAction<userTypes[]>) {
      state.users = action.payload;
    },
  },
});

export const { getUsersSuccess } = usersStore.actions;
