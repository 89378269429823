import React, { useEffect, useState } from 'react';
import css from './ChatList.module.scss';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useIsInternalMember } from '../../../hooks/useRoles';
import SingleChatList from './SingleChatList/SingleChatList';

interface ChatListProps {
  onSelected: Function;
  formatMessages: any;
  chatType: 'internal' | 'external';
  changeChatType: (chat: any) => any;
}

const ChatList: React.FC<ChatListProps> = ({
  onSelected,
  formatMessages,
  chatType,
  changeChatType,
}) => {
  const isIntMember = useIsInternalMember();

  const folderMessages = useSelector((state: RootState) => state.chat.get.messages);
  const formats = useSelector((state: RootState) => state.formats.formats);

  const [intMessages, setIntMessages] = useState<any>([]);
  const [extMessages, setExtMessages] = useState<any>([]);

  useEffect(() => {
    let destinationMessages;
    if (chatType === 'internal') {
      destinationMessages = folderMessages.filter((m: any) => m.destination_chat === 'internal');
      setIntMessages(destinationMessages);
    } else if (chatType === 'external') {
      destinationMessages = folderMessages.filter((m: any) => m.destination_chat === 'external');
      setExtMessages(destinationMessages);
    }
  }, [chatType, folderMessages]);

  return (
    <div className={css.wrapper}>
      <div className={css.row}>
        <h3>Chat</h3>
      </div>

      {isIntMember && (
        <ul className={css.doubleTabs}>
          <li
            className={cx(chatType === 'internal' && css.active)}
            onClick={() => changeChatType('internal')}
          >
            Crafted
          </li>
          <li
            className={cx(chatType === 'external' && css.active)}
            onClick={() => changeChatType('external')}
          >
            Sky
          </li>
        </ul>
      )}

      <div className={css.chatListContainer}>
        <ul className={css.chatList}>
          {chatType === 'internal' && isIntMember && (
            <>
              <SingleChatList
                title='General'
                lastMessage={intMessages?.slice(-1)[0]}
                onSelected={(id: number) => onSelected(id)}
                messageCounter={
                  intMessages.length > 0
                    ? intMessages.filter(
                        (m: any) =>
                          (m.read_info.length === 0 && m.destination_chat === 'internal') ||
                          (m.read_info.length === 0 && m.type === 'system'),
                      ).length
                    : 0
                }
              />
              {formats.map((format: any) => {
                const messageGroup = formatMessages.find((fm: any) => fm.id === format.id);
                const lastMessage = messageGroup?.messages
                  .filter((m: any) => m.destination_chat === 'internal')
                  .slice(-1)[0];
                return (
                  <SingleChatList
                    key={format.id}
                    title={format.versions.slice(-1)[0].title}
                    format={format}
                    lastMessage={lastMessage}
                    onSelected={(id: number) => onSelected(id)}
                    messageCounter={
                      formatMessages
                        .find((fm: any) => fm.id === format.id)
                        ?.messages.filter(
                          (m: any) =>
                            (m.read_info.length === 0 && m.destination_chat === 'internal') ||
                            (m.read_info.length === 0 && m.type === 'system'),
                        ).length || 0
                    }
                  />
                );
              })}
            </>
          )}

          {chatType === 'external' && (
            <>
              <SingleChatList
                title='General'
                lastMessage={extMessages?.slice(-1)[0]}
                onSelected={(id: number) => onSelected(id)}
                messageCounter={
                  extMessages.length > 0
                    ? extMessages.filter(
                        (m: any) => m.read_info.length === 0 && m.destination_chat === 'external',
                      ).length
                    : 0
                }
              />
              <>
                {formats.map((format: any) => {
                  if (format.visibility) {
                    const messageGroup = formatMessages.find((fm: any) => fm.id === format.id);
                    const lastMessage = messageGroup?.messages
                      .filter((m: any) => m.destination_chat === 'external')
                      .slice(-1)[0];
                    return (
                      <SingleChatList
                        key={format.id}
                        title={format.versions.slice(-1)[0].title}
                        format={format}
                        lastMessage={lastMessage}
                        onSelected={(id: number) => onSelected(id)}
                        messageCounter={
                          formatMessages
                            .find((fm: any) => fm.id === format.id)
                            ?.messages.filter(
                              (m: any) =>
                                m.read_info.length === 0 && m.destination_chat === 'external',
                            ).length || 0
                        }
                      />
                    );
                  }
                  return null;
                })}
              </>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ChatList;
