import React, { useEffect, useState } from 'react';
import css from './../DashboardCampaign.module.scss';
import cx from 'classnames';
import moment from 'moment';
import Icon from '../../../../components/UI/Icon/Icon';

interface DashboardCreativityProps {
  creativity: CreativityTypes;
}

const DashboardCreativity: React.FC<DashboardCreativityProps> = ({ creativity }) => {
  const [currentStep, setCurrentStep] = useState<StepTypes>();

  useEffect(() => {
    if (creativity.steps) {
      const current = creativity.steps.find((step: StepTypes) => step.status === 'current');
      if (current) {
        setCurrentStep(current);
      }
    }
  }, [creativity.steps]);

  const isCompleted = (step: any) => {
    if (step.status === 'current') {
      return true;
    }

    const now = new Date().getTime();
    const begin = new Date(step.beginDate).getTime();

    if (currentStep) {
      return begin <= new Date(currentStep.beginDate).getTime();
    }

    return begin <= now;
  };

  return (
    <>
      <div className={css.creativityBox}>
        <h3>{creativity.name}</h3>
        <p>
          Online il {moment(creativity.steps[creativity.steps.length - 1].endDate).format('D MMMM')}
        </p>

        <div className={css.creativityPhases}>
          <ul>
            {creativity.steps?.map((step: any) => (
              <li
                key={step.id}
                className={cx({
                  [css.completed]: isCompleted(step),
                })}
              ></li>
            ))}
          </ul>
        </div>
        {currentStep && (
          <div className={css.nextPhaseSchedule}>
            <Icon icon='clock_s' gray></Icon>
            {currentStep && (
              <span>
                {moment(currentStep.endDate).diff(moment.now(), 'days')} gg alla nuova fase
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardCreativity;
