import React from 'react';
import css from './Creativities.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import Icon from '../../../components/UI/Icon/Icon';
import { useIsInternalMember } from '../../../hooks/useRoles';
import CampaignCreativity from './CampaignCreativity/CampaignCreativity';

const Creativities: React.FC = () => {
  const inIntOwner = useIsInternalMember();

  const creativities = useSelector((state: RootState) => state.creativities.creativities);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  return (
    <div className={css.wrapper}>
      {creativities.map((creativity: CreativityTypes) => (
        <CampaignCreativity
          key={creativity.id}
          creativity={creativity}
          notifications={notifications.filter(
            (notification: any) => notification.creativity_id === creativity.id,
          )}
        />
      ))}
      {inIntOwner && creativities.length <= 0 && (
        <Link className={css.addBox} to={`/newcreativity`}>
          <span>
            <Icon icon='add-circle' gray></Icon>
          </span>
          <div>Crea una nuova creatività</div>
        </Link>
      )}
    </div>
  );
};

export default Creativities;
