import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const campaignsStore = createSlice({
  name: 'campaigns',
  initialState: {
    campaigns: [] as CampaignTypes[],
  },
  reducers: {
    getCampaignsSuccess(state, action: PayloadAction<CampaignTypes[]>) {
      state.campaigns = action.payload;
    },
  },
});

export const { getCampaignsSuccess } = campaignsStore.actions;
