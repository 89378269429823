import React, { useEffect, useState } from 'react';
import css from '../Formats.module.scss';
import { useDispatch } from 'react-redux';
import Button from '../../../../components/UI/Button/Button';
import Icon from '../../../../components/UI/Icon/Icon';
import { useIsOwner, useIsExternalOwner, useIsInternalMember } from '../../../../hooks/useRoles';
import { bulkDeleteFormats, bulkUpdateFormats } from '../../../../store/formats/api';
import ApprovalManager from '../Format/ApprovalManager/ApprovalManager';

interface BulkBarTypes {
  selectedFormats: any;
  approveModal: () => void;
  folder: any;
}

const BulkBar: React.FC<BulkBarTypes> = ({ selectedFormats, approveModal, folder }) => {
  const dispatch = useDispatch();

  const isIntOwner = useIsOwner();
  const isExtOwner = useIsExternalOwner();
  const isIntMember = useIsInternalMember();

  const [visibilityStatus, setVisibilityStatus] = useState<boolean>(false);
  const [approvals, setApprovals] = useState<any[]>([]);

  const updateVisibilityHandler = () => {
    dispatch(bulkUpdateFormats(selectedFormats, folder.id));
  };

  const deleteFormatsHandler = () => {
    dispatch(bulkDeleteFormats(selectedFormats, folder.id));
  };

  useEffect(() => {
    // Check if visibility is same for selected formats
    let isSameVis = true;
    const firstVis = selectedFormats[0].visibility;
    selectedFormats.forEach((f: any) => {
      if (f.visibility !== firstVis) {
        isSameVis = false;
      }
    });
    setVisibilityStatus(isSameVis);

    // Check if approvals is same for selected formats
    let smallestFormatApproval = selectedFormats[0].approvals;

    selectedFormats.forEach((format: any) => {
      if (format.approvals.length < smallestFormatApproval.length) {
        smallestFormatApproval = format.approvals;
      }
    });

    const finalApprovals: any[] = [];
    let approvalsDesignation: string = 'user';

    if (selectedFormats.length > 1) {
      smallestFormatApproval.forEach((approver: any) => {
        const approverFormatted = {
          userId: approver.user.id,
          status: approver.status,
          designation: approver.user.designation,
        };

        let isEqual = true;
        selectedFormats.forEach((format: any) => {
          format.approvals.forEach((item: any) => {
            if (item.user.designation === 'client') {
              approvalsDesignation = 'client';
            }
          });

          let isInThisFormat = true;
          if (format.approvals !== smallestFormatApproval) {
            if (
              format.approvals.some(
                (item: any) =>
                  item.user.id === approverFormatted.userId &&
                  item.status === approverFormatted.status,
              )
            ) {
              return;
            } else {
              isInThisFormat = false;
            }
          }

          if (!isInThisFormat) {
            isEqual = false;
          }
        });

        if (isEqual && approver.user.designation === approvalsDesignation) {
          finalApprovals.push(approver);
        }
      });
    }

    setApprovals(finalApprovals);
  }, [selectedFormats]);

  return (
    <div className={css.selectedElementsBar}>
      <div className={css.selectedElementsBarLeft}>
        {selectedFormats.length} formati selezionati
      </div>

      {approvals.length > 0 && (
        <>
          <ApprovalManager
            selectedFormats={selectedFormats}
            folderId={folder.id}
            approvals={approvals}
            showSendForApprovalButton={false}
            format={selectedFormats}
          />
          {(isIntOwner || isExtOwner) && (
            <span style={{ marginLeft: 5 }}>
              <Icon icon='add-circle' gray onClick={approveModal} />
            </span>
          )}
        </>
      )}

      {(isIntOwner || isExtOwner) && approvals.length === 0 && (
        <div className={css.sendForApproval} onClick={approveModal}>
          <Button text='Richiedi approvazione' />
        </div>
      )}

      <div className={css.selectedElementsBarRight}>
        {isIntOwner && (
          <>
            {visibilityStatus && (
              <div onClick={() => updateVisibilityHandler()}>
                <Icon icon={selectedFormats[0].visibility ? 'details' : 'details-off'} gray />
              </div>
            )}
          </>
        )}

        {isIntMember && (
          <div onClick={() => deleteFormatsHandler()}>
            <Icon icon='trash' gray />
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkBar;
