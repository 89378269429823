import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { RootState } from '../../store/store';
import { postResetPassword } from '../../store/auth/api';
import { useParams } from 'react-router-dom';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Parse from 'html-react-parser';
import css from './RessetPassword.module.scss';

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();

  type ParamTypes = { token: string };
  const { token } = useParams<ParamTypes>();

  const resetState = useSelector((state: RootState) => state.auth.resetpassword);

  const { control, errors, handleSubmit } = useForm<ResetPasswordForm>();

  const onSubmit = (data: ResetPasswordForm) => {
    dispatch(postResetPassword(data, token));
  };

  return (
    <div className={css.ressetPwdWrapper}>
      <div className={css.hero}>
        <div className={css.heroContent}>
          <div className={css.img}>
            <img src='https://picsum.photos/512/372' alt='immagine' />
          </div>
          <div className={css.welcome}>
            <h3>Benvenuto in Rio</h3>
            <p>Un unico spazio per la gestione di tutte le campagne digital.</p>
          </div>
          {/* <div className={css.sliderDots}>
          <ul>
            <li className={css.active}></li>
            <li></li>
            <li></li>
          </ul>
        </div> */}
        </div>
      </div>
      <form className={`${css.formContainer} ${css.prova}`} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.formContent}>
          <h2>Resetta password</h2>
          <div className={css.formGroup}>
            <Controller
              name='password'
              type='password'
              as={Input}
              control={control}
              defaultValue=''
              rules={{
                required: 'Il campo Password non può essere vuoto',
              }}
              label='Nuova password <span>*</span>'
              error={errors.password ? 1 : 0}
            />
            <span className={css.err}>{errors.password && errors.password.message}</span>
          </div>
          <Button text='Resetta password' inLoad={resetState.loading} />
          <br />

          {resetState.error && resetState.error}
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
