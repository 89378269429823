import React from 'react';
import Button from '../Button/Button';
import css from './Modal.module.scss';

interface ModalProps {
  isOpen: boolean;
  title: string;
  children?: React.ReactNode;
  submitButton?: {
    text: string;
    type?: string;
    inLoad?: boolean;
  };
  onSubmit?: () => void;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  isOpen,
  submitButton,
  onSubmit,
  onClose,
}) => {
  return (
    <>
      {isOpen && (
        <div className={css.modal}>
          <div className={css.modalContent}>
            <div className={css.modalHeader}>
              <h3>{title}</h3>
            </div>
            <div className={css.modalBody}>{children}</div>
            <div className={css.modalFooter}>
              <Button text='Annulla' styleCancel onClick={onClose} />
              <Button
                text={submitButton?.text || ''}
                styleDanger={submitButton?.type === 'danger' && true}
                onClick={onSubmit}
                inLoad={submitButton?.inLoad}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
