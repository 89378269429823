import { AppThunk } from '../store';
import customAxios from '../../axios-configurator';
import {
  getFormatsLoading,
  getFormatsSuccess,
  postAddFormatLoading,
  postAddFormatError,
  postAddFormatSuccess,
  postUpdateFormatLoading,
  postUpdateFormatSuccess,
  postAddFolderLoading,
  postAddFolderError,
  postAddFolderSuccess,
  editFolderLoading,
  editFolderSuccess,
} from './slice';
import { getCreativities } from '../creativities/api';
import {
  deleteApprovalRequestError,
  deleteApprovalRequestLoading,
  deleteApprovalRequestSuccess,
} from '../approval/slice';
import { getNotifications } from '../notifications/api';

export const getFormats =
  (folderId: number, loading: boolean = true): AppThunk =>
  async dispatch => {
    try {
      if (loading) {
        dispatch(getFormatsLoading());
      }
      const res = await customAxios.get(`/creativities/formats/${folderId}?limit=1000`);
      dispatch(getFormatsSuccess(res.data));
    } catch (err) {
      console.log(err);
    }
  };

export const postAddFormat =
  (data: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(postAddFormatLoading());
      await customAxios.post(`/creativities/formats/${data.creativity_folder_id}`, {
        attachments: data.attachments,
        images: data.images,
        content: data.content,
        title: data.title,
        creativity_folder_id: data.creativity_folder_id,
      });
      dispatch(postAddFormatSuccess());
      dispatch(getFormats(data.creativity_folder_id));
    } catch (err: any) {
      dispatch(postAddFormatError(err.response.data.error));
    }
  };

export const updateFormatVisibility =
  (formatId: number, visibility: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(postUpdateFormatLoading());
      const res = await customAxios.put(`/creativities/formats/${formatId}`, {
        visibility,
      });
      dispatch(getFormats(res.data.creativity_folder_id, false));
      dispatch(postUpdateFormatSuccess());
    } catch (err) {
      console.log(err);
    }
  };

export const updateFormatVersion =
  (data: any, versionId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(postUpdateFormatLoading());
      const res = await customAxios.put(`/creativities/formats/version/${versionId}`, {
        ...data,
        visibility: 0,
      });
      dispatch(getFormats(res.data.creativity_folder_id));
      dispatch(postUpdateFormatSuccess());
    } catch (err) {
      console.log(err);
    }
  };

export const createNewVersion =
  (data: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(postUpdateFormatLoading());
      const res = await customAxios.put(`/creativities/formats/${data.id}`, data);
      dispatch(postUpdateFormatSuccess());
      dispatch(getFormats(res.data.creativity_folder_id));
    } catch (err) {
      //dispatch(postAddFormatError(err.response.data.error));
    }
  };

export const deleteFormat =
  (formatId: number, folderId: number): AppThunk =>
  async dispatch => {
    try {
      await customAxios.delete(`/creativities/formats/${formatId}`);
      dispatch(getFormats(folderId));
    } catch (err) {
      console.log(err);
    }
  };

interface postAddFolerType {
  name: string;
  creativityId: number;
}

export const postAddFolder =
  (data: postAddFolerType): AppThunk =>
  async (dispatch, getState) => {
    const campaignId = getState().campaign.campaign.id;
    try {
      dispatch(postAddFolderLoading());
      await customAxios.post(`/creativities/folders/${data.creativityId}`, { name: data.name });

      dispatch(postAddFolderSuccess());
      dispatch(getCreativities(campaignId));
    } catch (err: any) {
      dispatch(postAddFolderError(err.response.data.error));
    }
  };

interface putEditFolerType {
  name: string;
  folderId: number;
}

export const putEditFolder =
  (data: putEditFolerType): AppThunk =>
  async (dispatch, getState) => {
    const campaignId = getState().campaign.campaign.id;
    try {
      dispatch(editFolderLoading());
      await customAxios.put(`/creativities/folders/${data.folderId}`, { name: data.name });

      dispatch(editFolderSuccess());
      dispatch(getCreativities(campaignId));
    } catch (err) {
      console.log(err);
    }
  };

export const deleteFolder =
  (folderId: number): AppThunk =>
  async (dispatch, getState) => {
    const campaignId = getState().campaign.campaign.id;
    try {
      dispatch(editFolderLoading());
      await customAxios.delete(`/creativities/folders/${folderId}`);
      dispatch(editFolderSuccess());
      dispatch(getCreativities(campaignId));
    } catch (err) {
      console.log(err);
    }
  };

export const bulkUpdateFormats =
  (data: any, folderId: number): AppThunk =>
  async dispatch => {
    const dataToSend: any[] = [];

    data.forEach((el: any) => {
      dataToSend.push({ id: el.format, visibility: !el.visibility });
    });

    try {
      dispatch(postUpdateFormatLoading());
      await customAxios.put('/creativities/formats/bulkUpdate', dataToSend);
      dispatch(postUpdateFormatSuccess());
      dispatch(getFormats(folderId, false));
    } catch (err) {
      console.log(err);
    }
  };

export const bulkDeleteFormats =
  (data: any, folderId: number): AppThunk =>
  async dispatch => {
    const dataToSend: any[] = [];

    data.forEach((el: any) => {
      dataToSend.push({ id: el.format });
    });

    try {
      dispatch(postUpdateFormatLoading());
      await customAxios.delete('/creativities/formats/bulkDelete', { data: dataToSend });
      dispatch(postUpdateFormatSuccess());
      dispatch(getFormats(folderId, false));
    } catch (err) {
      console.log(err);
    }
  };

export const deleteApprovalRequest =
  (app: any, folderId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(deleteApprovalRequestLoading());
      await customAxios.post(`/creativities/formats/approvals/delete`, app);
      dispatch(deleteApprovalRequestSuccess());
      dispatch(getFormats(folderId));
      dispatch(getNotifications());
    } catch (err: any) {
      dispatch(deleteApprovalRequestError(err.response.data.error));
    }
  };

export const deleteMultipleApprovaRequest =
  (app: any, folderId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(deleteApprovalRequestLoading());
      await Promise.all(
        app.map((a: any) => customAxios.post('/creativities/formats/approvals/delete', a)),
      );
      dispatch(deleteApprovalRequestSuccess());
      dispatch(getFormats(folderId));
      dispatch(getNotifications());
    } catch (err: any) {
      dispatch(deleteApprovalRequestError(err.response.data.error));
    }
  };
