import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const companyStore = createSlice({
  name: 'company',
  initialState: {
    company: {} as any,
  },
  reducers: {
    getCompanySuccess(state, action: PayloadAction<any>) {
      state.company = action.payload;
    },
  },
});

export const { getCompanySuccess } = companyStore.actions;
