import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import css from './inputDate.module.scss';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Icon from '../Icon/Icon';
import parse from 'html-react-parser';

const InputDate = React.forwardRef((props: any, ref: React.Ref<ReactDatePickerProps>) => {
  const CustomDateInput = React.forwardRef((props: any, ref: any) => (
    <div className={css.wrapper}>
      <input
        className={css.input}
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
        ref={ref}
      />
      <Icon icon='calendar' gray />
    </div>
  ));

  return (
    <>
      {props.label && <label className='label'>{props.label}</label>}
      <DatePicker ref={ref} customInput={<CustomDateInput />} {...props} />
    </>
  );
});

export default InputDate;
